import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from 'src/app/_services/registration.service';

@Component({
    selector: 'app-checkout-complete',
    templateUrl: './checkout-complete.component.html',
    styleUrls: ['./checkout-complete.component.scss']
})
export class CheckoutCompleteComponent implements OnInit, AfterViewInit {

    checkoutSessionId: string | undefined;
    regId: string = "";
    checkoutStatus: string = "unknown"; //paid, unpaid, or no_payment_required
    loginURL: string = "";
    adminEmail: string = "";
    tempPwd: string = "";
    isLoading: boolean = true;

    setupInprogress: boolean = false;
    setupComplete: boolean = false;
    setupProvisioned: boolean = false;

    constructor(private regService: RegistrationService, private route: ActivatedRoute) { }

    ngOnInit(): void {

        this.route.params.subscribe(async param => {
            this.checkoutSessionId = param['checkoutSessionId'];
            console.log('Checkout session:', this.checkoutSessionId);

            if (!this.checkoutSessionId)
                throw new Error('No_Checkout_SessionId');

            this.getCheckoutStatus();
        });
    }

    ngAfterViewInit(): void {
    }

    private getCheckoutStatus() {

        this.regService.getCheckoutStatus(this.checkoutSessionId!).subscribe({
            next: regStatus => this.processCheckoutResult(regStatus),
            error: err => this.paymentError(err)
        });
    }

    private processCheckoutResult(regStatus: any) {
        console.log('Payment status', regStatus);
        this.regId = regStatus.regId;
        this.checkoutStatus = regStatus.paymentStatus.toLowerCase();
        if (this.checkoutStatus === 'paid' || this.checkoutStatus === 'no_payment_required')
            this.provisionServices();
        else {
            this.paymentFailed();
        }
    }

    private provisionServices() {
        try {
            if (!this.regId)
                throw new Error('No_Registration_Id');

            //enable spinner
            this.setupInprogress = true;

            this.regService.provisionService(this.regId).subscribe({ 
                next: serviceDetails => this.displayServiceDetails(serviceDetails),

                error: err => {
                    console.error('Error provisioning service', err);
                    this.setupComplete = true;
                    this.setupInprogress = false;
                }
            });
        } catch (err) {
            console.error('Error provisioning service', err);
        }
    }

    private displayServiceDetails(serviceDetails:any){
        //disable spinner
        this.setupProvisioned = true;
        this.setupInprogress = false;
        
        this.loginURL = serviceDetails.loginURL;
        this.adminEmail = serviceDetails.adminEmail;
        this.tempPwd = serviceDetails.tempPwd
    }

    private paymentFailed() {
        //this.checkoutStatus = "Your payment was unsuccessful";
    }

    private paymentError(err: any) {
        this.checkoutStatus = "error";
        console.error('Error processing payment', err);
    }
}
