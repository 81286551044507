<div  [style.showPolicyList]="selectedDocument == ''" [class.hidePolicyList]="selectedDocument != '' ">
    <div class="container">
        <a  class="button" style="color: white;" (click)="displayDoc('')">Back</a>
        <h1>Policies, Terms and Conditions</h1>
        <ul>
            <li><a (click)="displayDoc('termsOfService')">General Terms Of Service</a></li>
            <li><a (click)="displayDoc('refundPolicy')">Refund Policy</a></li>
            <li><a (click)="displayDoc('cancellationPolicy')">Cancellation Policy</a></li>
            <li><a (click)="displayDoc('dataPrivacy')">Consumer Data Privacy Policy</a></li>
        </ul>
    </div>
</div>

<div id="termsOfService" [style.showTermsOfService]="selectedDocument == 'termsOfService'" [class.hideTermsOfService]="selectedDocument != 'termsOfService' ">
   
    <div class="container">
        <a  class="button" (click)="displayDoc('')">Back</a>
        <h1>Terms and Conditions</h1>

        <h2>1. Introduction</h2>
        <p>Welcome to Creative Communications Lab Limited ("Company"). These Terms and Conditions ("Terms") govern your use of our Customer Experience SaaS product, Iconic.cx ("Service"). By using our Service, you agree to comply with these Terms. If you do not agree with any part of these Terms, you must not use our Service.</p>

        <h2>2. Definitions</h2>
        <p><strong>Service:</strong> The Customer Experience SaaS product provided by the Company, known as Iconic.cx.</p>
        <p><strong>User:</strong> Any individual or entity using the Service.</p>
        <p><strong>Customer:</strong> The entity that enters into a subscription agreement with the Company to use the Service.</p>
        <p><strong>Agreement:</strong> The subscription agreement between the Customer and the Company.</p>
        <p><strong>Confidential Information:</strong> Any information disclosed by one party to the other that is identified as confidential or that ought reasonably to be considered confidential.</p>

        <h2>3. Service Use</h2>
        <h3>3.1 License Grant</h3>
        <p>The Company grants the Customer a non-exclusive, non-transferable, limited right to access and use the Service in accordance with these Terms and the Agreement.</p>

        <h3>3.2 Acceptable Use</h3>
        <p>Users must not:</p>
        <ul>
            <li>Use the Service in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these Terms.</li>
            <li>Attempt to gain unauthorized access to any parts of the Service or any systems or networks connected to the Service.</li>
            <li>Use the Service to transmit any harmful or illegal content.</li>
        </ul>

        <h3>3.3 User Responsibilities</h3>
        <p>Customers are responsible for:</p>
        <ul>
            <li>Ensuring that all users comply with these Terms.</li>
            <li>Maintaining the confidentiality of any login credentials.</li>
            <li>All activities that occur under their account.</li>
        </ul>

        <h2>4. Fees and Payment</h2>
        <h3>4.1 Fees</h3>
        <p>The Customer agrees to pay the fees specified in the Agreement. Fees are based on the subscription plan selected.</p>

        <h3>4.2 Payment Terms</h3>
        <p>Payments are due as specified in the Agreement. The Company reserves the right to suspend access to the Service for any overdue payments.</p>

        <h3>4.3 Taxes</h3>
        <p>The Customer is responsible for all applicable taxes, except those based on the Company's income.</p>

        <h2>5. Term and Termination</h2>
        <h3>5.1 Term</h3>
        <p>The term of the Agreement will be specified in the subscription plan.</p>

        <h3>5.2 Termination for Convenience</h3>
        <p>Either party may terminate the Agreement by providing notice as specified in the Agreement.</p>

        <h3>5.3 Termination for Cause</h3>
        <p>The Company may terminate the Agreement immediately if the Customer breaches any of these Terms and fails to remedy the breach within a specified time period after being notified.</p>

        <h3>5.4 Effect of Termination</h3>
        <p>Upon termination, the Customer must cease all use of the Service. The Company may delete any data associated with the Customer's account after a specified period post-termination.</p>

        <h2>6. Confidentiality</h2>
        <h3>6.1 Obligations</h3>
        <p>Each party agrees to protect the other's Confidential Information with the same degree of care it uses to protect its own confidential information, but no less than reasonable care.</p>

        <h3>6.2 Permitted Disclosures</h3>
        <p>A party may disclose Confidential Information if required by law, provided it gives prior notice to the other party.</p>

        <h2>7. Intellectual Property</h2>
        <h3>7.1 Ownership</h3>
        <p>The Company retains all rights, title, and interest in and to the Service and all related intellectual property.</p>

        <h3>7.2 Feedback</h3>
        <p>Any feedback provided by the Customer regarding the Service may be used by the Company without restriction.</p>

        <h2>8. Warranties and Disclaimers</h2>
        <h3>8.1 Warranties</h3>
        <p>The Company warrants that the Service will perform substantially in accordance with the documentation provided.</p>

        <h3>8.2 Disclaimer</h3>
        <p>Except as expressly provided, the Service is provided "as is" and the Company disclaims all other warranties, whether express or implied, including the warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

        <h2>9. Limitation of Liability</h2>
        <h3>9.1 Limitation</h3>
        <p>The Company's liability for any claim arising out of or relating to these Terms or the Service will be limited to the amount paid by the Customer in the 12 months preceding the claim.</p>

        <h3>9.2 Exclusion</h3>
        <p>The Company will not be liable for any indirect, incidental, special, or consequential damages.</p>

        <h2>10. Indemnification</h2>
        <p>The Customer agrees to indemnify and hold harmless the Company from any claims, damages, or expenses arising from their use of the Service or violation of these Terms.</p>

        <h2>11. Miscellaneous</h2>
        <h3>11.1 Governing Law</h3>
        <p>These Terms will be governed by and construed in accordance with the laws of England and Wales.</p>

        <h3>11.2 Changes to Terms</h3>
        <p>The Company may modify these Terms at any time by posting the revised terms on its website. Continued use of the Service constitutes acceptance of the revised terms.</p>

        <h3>11.3 Entire Agreement</h3>
        <p>These Terms, together with the Agreement, constitute the entire agreement between the parties and supersede all prior agreements or understandings.</p>

        <h3>11.4 Notices</h3>
        <p>All notices under these Terms must be in writing and will be deemed to have been duly given when received.</p>
        <br/>
        <a  (click)="displayDoc('')">Back</a>
    </div>
</div>

<div id="refundPolicy" [style.showcancellationPolicy]="selectedDocument == 'refundPolicy'" [class.hidecancellationPolicy]="selectedDocument != 'refundPolicy' ">
        <div  class="button" class="container">
            <a (click)="displayDoc('')">Back</a>

            <h1>Refund and Dispute Policy</h1>
    
            <p>Effective Date: 23 May 2024</p>
    
            <h2>1. Introduction</h2>
            <p>This Refund and Dispute Policy ("Policy") outlines the procedures and guidelines for handling refund requests and disputes for the Iconic.cx SaaS platform ("Service") provided by Creative Communications Lab Limited ("Company"). By using our Service, you agree to this Policy.</p>
    
            <h2>2. Refund Policy</h2>
            
            <h3>2.1 Eligibility for Refunds</h3>
            <p>Refunds may be issued under the following circumstances:</p>
            <ul>
                <li>Technical issues that cannot be resolved by the Company within a reasonable timeframe.</li>
                <li>Service not provided as described or agreed upon in the subscription agreement.</li>
                <li>Billing errors where the Customer has been overcharged.</li>
            </ul>
    
            <h3>2.2 Non-Refundable Circumstances</h3>
            <p>Refunds will not be issued for:</p>
            <ul>
                <li>Change of mind or lack of usage of the Service.</li>
                <li>Issues arising from non-compliance with the Service’s terms and conditions.</li>
                <li>Partial periods of service or unused credits.</li>
            </ul>
    
            <h3>2.3 Refund Request Process</h3>
            <p>To request a refund, please follow these steps:</p>
            <ul>
                <li>Contact our support team at <a href="mailto:support@iconic.cx">support@iconic.cx</a> within 30 days of the issue arising.</li>
                <li>Provide your account details, a description of the issue, and any relevant documentation.</li>
                <li>Our support team will review your request and respond within 10 business days.</li>
            </ul>
    
            <h3>2.4 Refund Method</h3>
            <p>Approved refunds will be processed to the original payment method within 14 business days of approval.</p>
    
            <h2>3. Dispute Resolution</h2>
            
            <h3>3.1 Informal Resolution</h3>
            <p>We encourage Customers to attempt to resolve any disputes informally by contacting our support team at <a href="mailto:support@iconic.cx">support@iconic.cx</a>.</p>
    
            <h3>3.2 Formal Dispute Process</h3>
            <p>If informal resolution is not possible, the following steps should be taken:</p>
            <ul>
                <li>Provide a written notice of the dispute to the Company, detailing the nature of the dispute and any relevant supporting documentation.</li>
                <li>The Company will acknowledge receipt of the dispute notice within 5 business days and investigate the matter.</li>
                <li>Both parties will engage in good faith negotiations to resolve the dispute within 30 days of the notice.</li>
            </ul>
    
            <h3>3.3 Escalation to Mediation or Arbitration</h3>
            <p>If the dispute cannot be resolved through negotiation, either party may escalate the matter to mediation or binding arbitration in accordance with the laws of England and Wales.</p>
    
            <h2>4. Governing Law</h2>
            <p>This Policy is governed by and construed in accordance with the laws of England and Wales. Any disputes arising under or in connection with this Policy shall be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
    
            <h2>5. Changes to this Policy</h2>
            <p>The Company reserves the right to modify this Policy at any time. Changes will be effective immediately upon posting the updated Policy on our website. Continued use of the Service after any changes constitutes acceptance of the revised Policy.</p>
    
            <h2>6. Contact Us</h2>
            <p>If you have any questions about this Policy, please contact us at:</p>
            <p>Email: <a href="mailto:support@iconic.cx">support@iconic.cx</a></p>
            <p>Address: Creative Communications Lab Limited, 86 - 90 Paul Street London EC2A 4NE, United Kingdom</p>
            <br/>
            <a  class="button" (click)="displayDoc('')">Back</a>
        </div>
</div>

<div id="cancellationPolicy" [style.showCancellationPolicy]="selectedDocument == 'cancellationPolicy'" [class.hideCancellationPolicy]="selectedDocument != 'cancellationPolicy' ">
        <div class="container">
            <a class="button" (click)="displayDoc('')">Back</a>
            <h1>Cancellation Policy</h1>
    
            <p>Effective Date: 23 November 2023</p>
    
            <h2>1. Introduction</h2>
            <p>This Cancellation Policy ("Policy") outlines the procedures and guidelines for the cancellation, termination, and suspension of subscriptions to the Iconic.cx SaaS platform ("Service") provided by Creative Communications Lab Limited ("Company"). By using our Service, you agree to this Policy.</p>
    
            <h2>2. Cancellation by Customers</h2>
            
            <h3>2.1 Monthly Subscriptions</h3>
            <p>Customers with monthly subscriptions can cancel their subscription at any time. The cancellation will take effect at the end of the current billing cycle. No refunds will be provided for the remaining days of the subscription period.</p>
    
            <h3>2.2 Annual Subscriptions</h3>
            <p>Customers with annual subscriptions can cancel their subscription at any time. The cancellation will take effect at the end of the current annual billing cycle. No refunds will be provided for the remaining months of the subscription period.</p>
    
            <h3>2.3 Cancellation Process</h3>
            <p>To cancel a subscription, Customers must:</p>
            <ul>
                <li>Log into their account as an administrator.</li>
                <li>Navigate to the organisation page.</li>
                <li>Follow the prompts to cancel the subscription.</li>
                <li>Alternatively, contact our support team at <a href="mailto:support@iconic.cx">support@iconic.cx</a> for assistance with the cancellation process.</li>
            </ul>
    
            <h2>3. Termination by Company</h2>
            
            <h3>3.1 Termination for Cause</h3>
            <p>The Company reserves the right to terminate a subscription immediately if the Customer breaches any of the Service's terms and conditions, including but not limited to:</p>
            <ul>
                <li>Non-payment of fees.</li>
                <li>Violation of acceptable use policies.</li>
                <li>Engagement in fraudulent or illegal activities.</li>
            </ul>
    
            <h3>3.2 Termination without Cause</h3>
            <p>The Company may terminate a subscription without cause by providing the Customer with at least 30 days' notice. In such cases, a pro-rata refund will be issued for any unused portion of the subscription period.</p>
    
            <h3>3.3 Effect of Termination</h3>
            <p>Upon termination, the Customer must immediately cease all use of the Service. The Company will disable the Customer's access to the Service and may delete any data associated with the Customer's account after a specified period post-termination.</p>
    
            <h2>4. Suspension of Service</h2>
            
            <h3>4.1 Grounds for Suspension</h3>
            <p>The Company may suspend access to the Service for the following reasons:</p>
            <ul>
                <li>Non-payment of fees.</li>
                <li>Suspected violation of the terms and conditions.</li>
                <li>To prevent damage or disruption to the Service, other Customers, or third parties.</li>
            </ul>
    
            <h3>4.2 Notice of Suspension</h3>
            <p>The Company will provide notice to the Customer prior to suspension, unless immediate suspension is necessary to prevent harm. The notice will include the reasons for the suspension and any steps required to lift the suspension.</p>
    
            <h3>4.3 Duration of Suspension</h3>
            <p>The suspension will remain in effect until the underlying issues are resolved to the Company's satisfaction. Failure to resolve the issues may result in termination of the subscription.</p>
    
            <h2>5. Reinstatement of Service</h2>
            <p>If a subscription is suspended or terminated for non-payment or breach of terms, the Customer may request reinstatement by contacting our support team at <a href="mailto:support@iconic.cx">support@iconic.cx</a>. Reinstatement is at the Company's discretion and may be subject to additional fees and conditions.</p>
    
            <h2>6. Changes to this Policy</h2>
            <p>The Company reserves the right to modify this Policy at any time. Changes will be effective immediately upon posting the updated Policy on our website. Continued use of the Service after any changes constitutes acceptance of the revised Policy.</p>
    
            <h2>7. Contact Us</h2>
            <p>If you have any questions about this Policy, please contact us at:</p>
            <p>Email: <a href="mailto:support@iconic.cx">support@iconic.cx</a></p>
            <p>Address: Creative Communications Lab Limited, 86 - 90 Paul Street London EC2A 4NE, United Kingdom</p>
            <br/>
            <a  class="button" (click)="displayDoc('')">Back</a>
        </div>
</div>

<div id="dataPrivacy" [style.showDataPrivacy]="selectedDocument == 'dataPrivacy'" [class.hidecDataPrivacy]="selectedDocument != 'dataPrivacy' ">
    <div class="container">
        <a  class="button" (click)="displayDoc('')">Back</a>
        <h1>Consumer Data Privacy Policy</h1>

        <p>Effective Date: 23 May 2024</p>

        <h2>1. Introduction</h2>
        <p>Creative Communications Lab Limited ("Company", "we", "us", "our") is committed to protecting the privacy and security of our users' data. This Consumer Data Privacy Policy ("Policy") describes how we collect, use, disclose, and protect personal data when you use our customer experience SaaS platform, Iconic.cx ("Service"). By using our Service, you agree to the terms of this Policy.</p>

        <h2>2. Data Collection</h2>
        
        <h3>2.1 Information We Collect</h3>
        <p>We may collect the following types of information:</p>
        <ul>
            <li><strong>Personal Information:</strong> Name, email address, phone number, postal address, and other contact details.</li>
            <li><strong>Account Information:</strong> Username, password, and other authentication details.</li>
            <li><strong>Customer Interactions:</strong> Emails, phone calls, web chats, text messages, and other communications managed through our platform.</li>
            <li><strong>CRM Data:</strong> Customer relationship management data, including customer preferences, purchase history, and feedback.</li>
            <li><strong>Technical Information:</strong> IP address, browser type, operating system, and other technical details collected through cookies and similar technologies.</li>
        </ul>

        <h3>2.2 How We Collect Information</h3>
        <p>We collect information in the following ways:</p>
        <ul>
            <li>Directly from you when you register for and use our Service.</li>
            <li>Automatically through your interactions with our Service (e.g., through cookies and similar technologies).</li>
            <li>From third parties, such as CRM systems, marketing platforms, and publicly available sources.</li>
        </ul>

        <h2>3. Use of Data</h2>
        
        <h3>3.1 Purpose of Data Use</h3>
        <p>We use the collected data to:</p>
        <ul>
            <li>Provide, operate, and maintain our Service.</li>
            <li>Improve and personalize your experience with our Service.</li>
            <li>Communicate with you, including responding to your inquiries and providing customer support.</li>
            <li>Analyze usage patterns and enhance the functionality of our Service.</li>
            <li>Comply with legal obligations and enforce our terms and policies.</li>
        </ul>

        <h3>3.2 Legal Basis for Processing</h3>
        <p>We process personal data based on the following legal grounds:</p>
        <ul>
            <li>Your consent.</li>
            <li>Performance of a contract.</li>
            <li>Compliance with legal obligations.</li>
            <li>Our legitimate interests, such as improving our Service and securing our operations.</li>
        </ul>

        <h2>4. Data Sharing and Disclosure</h2>
        
        <h3>4.1 Third-Party Service Providers</h3>
        <p>We may share your information with third-party service providers who perform services on our behalf, such as hosting, data analysis, payment processing, and customer support. These providers are obligated to protect your data and use it only as necessary to provide their services.</p>

        <h3>4.2 Legal Requirements</h3>
        <p>We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., court orders, government agencies).</p>

        <h3>4.3 Business Transfers</h3>
        <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal data may be transferred as part of the transaction. We will notify you of any such change in ownership or control of your personal data.</p>

        <h2>5. Data Security</h2>
        <p>We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure. While we strive to protect your data, we cannot guarantee its absolute security.</p>

        <h2>6. Data Retention</h2>
        <p>We retain personal data for as long as necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law. When personal data is no longer needed, we will securely delete or anonymize it.</p>

        <h2>7. Your Data Protection Rights</h2>
        <p>Under data protection laws, you have the following rights:</p>
        <ul>
            <li><strong>Access:</strong> You have the right to request copies of your personal data.</li>
            <li><strong>Rectification:</strong> You have the right to request that we correct any information you believe is inaccurate or incomplete.</li>
            <li><strong>Erasure:</strong> You have the right to request that we delete your personal data, under certain conditions.</li>
            <li><strong>Restriction of Processing:</strong> You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
            <li><strong>Data Portability:</strong> You have the right to request that we transfer your data to another organization, or directly to you, under certain conditions.</li>
            <li><strong>Object:</strong> You have the right to object to our processing of your personal data, under certain conditions.</li>
        </ul>
        <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our provided contact information.</p>

        <h2>8. Cookies and Similar Technologies</h2>
        <p>We use cookies and similar tracking technologies to collect data which is used by our platform to perform the functions required to fulfill the services we offer. You can manage your cookie preferences through your browser settings.</p>

        <h2>9. International Data Transfers</h2>
        <p>Your personal data may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different from the laws of your country. However, we take steps to ensure that your information is treated securely and in accordance with this Policy.</p>

        <h2>10. Changes to this Policy</h2>
        <p>We may update this Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the new Policy on our website. You are advised to review this Policy periodically for any changes.</p>

        <h2>11. Contact Us</h2>
        <p>If you have any questions or concerns about this Policy or our data practices, please contact us at:</p>
        <p>Email: <a href="mailto:privacy@iconic.cx">privacy@iconic.cx</a></p>
        <p>Address: Creative Communications Lab Limited, 86 - 90 Paul Street London EC2A 4NE, United Kingdom</p>
        <br/>
        <a  class="button" (click)="displayDoc('')">Back</a>
    </div>
</div>