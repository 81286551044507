<main class="main" id="top">
<div class="wrapper">
    <div class="header">
        <app-corporate-navbar></app-corporate-navbar>
    </div>
      
    <div class="middle">
        <router-outlet></router-outlet>
    </div>
    
</div>

</main>

<app-corporate-footer class="bottom"></app-corporate-footer>