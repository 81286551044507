<br/>
<br/>
<br/>

<div class="container">
    
    <h1>
       {{'checkout-complete.' + checkoutStatus.toString() | translate}}
    </h1>
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="registrationHelp"> 
                <h5> {{'registration.registrationHelp_title' | translate}}</h5>
                <p>{{'registration.registrationHelp_text' | translate}}</p>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 registration">
            <div *ngIf="checkoutStatus === 'unknown'">
                <h5>Please wait</h5>
                <p>We are confirming your payment</p>
            </div>

            <div *ngIf="setupInprogress">
                <h5>We are setting up your iconic.cx services</h5>
                

                <app-loading [displayLoading]="isLoading" #loading></app-loading>

                <p>
                    Please do not refresh or leave this page, it will just take a momment.
                </p>

            </div>

            <div *ngIf="setupProvisioned">
                <h2>You're all set!</h2>
                <p>Your iconic.cx Service Desk is now ready, here are your login details:</p>
                
                    <ul>
                        <li>
                            Login URL: {{loginURL}}
                        </li>
                        <li>
                            Admin username: {{adminEmail}}
                        </li>
                        <li>
                            Admin password {{tempPwd}}
                        </li>
                    </ul>
                
                <p>You'll be asked to change your password when you first login</p>
                <p>
                    We recommend logging in as soon as possible to customise your settings and start exploring the iconic.cx platform.
                </p>
                    <a class="btn btn-primary" href={{loginURL}}>Login Now</a>
                <p>
                   These details have been sent to you by email for your records. 
                </p>
                
            </div>

            <div *ngIf="setupComplete">
                <h5>Setup Complete</h5>
                <p>
                    Your iconic.cx services are being configured, you will recieve an email shortly with your login details and a link to your iconic Service Desk.
                </p>
                
            </div>
            
            <div *ngIf="checkoutStatus ==='unpaid'" class="paymentFailed" [class.showPaymentFailed]="checkoutStatus === 'unpaid'">
                <h2>We're unable to create your iconic services</h2>
                <p>Please check your payment details and try again</p>
                <a class="btn btn-primary" routerLink="/checkout/{{regId}}">Try Again</a>
            </div>
        </div>
    </div>
</div>