import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigValues } from '../_models/core';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  
  constructor(){}
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('HTTP Request Interceptor: Setting with Credentials to true');
    const token:string | null = localStorage.getItem(ConfigValues.authToken);
    const sessionId:string | null = localStorage.getItem(ConfigValues.sessionId);

    req = req.clone({
      withCredentials: true
    });

    if(token){
//      console.log('HTTP Request Interceptor: Setting with Authorization token', token);

      req = req.clone({
        setHeaders: {
          Authorization: token
        }
      })
    }

    if(sessionId){
      console.log('HTTP Request Interceptor: Setting with session id', sessionId);

      req = req.clone({
        setHeaders: {
          'session-id': sessionId
        }
      })
    }
    return next.handle(req);
  }
}