<section id={{tab?.key}} class="tab-pane fade" [class.show]="isActive" [class.active]="isActive">
    <div>
        <h2><mat-icon>manage_accounts</mat-icon>{{'admin.adminMenuTitle' | translate}}</h2>
        <hr/>
        <mat-card class="menu-card">
            <mat-card-header>
                <div mat-card-avatar class="menu-header-image"><mat-icon>person_add</mat-icon></div>
                <mat-card-title>{{ 'admin.registerUserTitle' | translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'admin.registerUserSubTitle' | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                {{ 'admin.registerUserDescription' | translate }}
            </mat-card-content>
            <mat-card-actions align="end">
                <button class="secondaryButton" mat-stroked-button color="accent" routerLink='admin/user/register' >{{ 'admin.registerUserTitle' | translate }}</button>
            </mat-card-actions>
        </mat-card>

        <mat-card class="menu-card">
            <mat-card-header>
                <div mat-card-avatar class="menu-header-image"><mat-icon>group</mat-icon></div>
                <mat-card-title>{{ 'admin.listUserTitle' | translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'admin.listUserSubTitle' | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                {{ 'admin.listUserDescription' | translate }}
            </mat-card-content>
            <mat-card-actions align="end">
                <button class="secondaryButton" mat-stroked-button color="accent" routerLink='admin/user' >{{ 'admin.listUserTitle' | translate }}</button>
            </mat-card-actions>
        </mat-card>
    </div>

    <div>
        <h2><mat-icon>touch_app</mat-icon>Touch Points</h2>
        <hr/>
        <mat-card class="menu-card">
            <mat-card-header>
                <div mat-card-avatar class="menu-header-image"><mat-icon>person_search</mat-icon></div>
                <mat-card-title>View Services</mat-card-title>
                <mat-card-subtitle>View all configured services</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                View all configured services
            </mat-card-content>
            <mat-card-actions align="end">
                <button class="secondaryButton" mat-stroked-button color="accent" routerLink='admin/service' >View Services</button>
            </mat-card-actions>
        </mat-card>
    </div>
</section>