export enum ConfigValues {
    userProfile = 'current-user',
    authToken = 'access-token',
    idToken = 'id-token',
    refreshToken = 'refresh-token',
    showSideBar = 'showSideBar',
    sessionId = 'session-id'
  }

  export enum SessionConnectionState {
    sessionOnline = 'SESSION_ONLINE',
    sessionOffline = 'SESSION_OFFLINE',
    sessionError = 'SESSION_ERROR'
  }
  
  export enum TaskType {
    Unknown = 0,
    WebChat = 1,
    Voice = 2,
    Video = 4,
    Email = 8
}

export enum MessageType {
  task = 'TASK',
  channelState = 'CHANNEL-STATE',
  taskActivity = 'TASK-ACTIVITY',
  sessionState = 'SESSION-STATE',
  taskAllocationConf = 'TASK-ALLOCATION-CONF',

  taskStateChange = 'TASK-STATE-CHANGE',
  taskInteractionStateChange = 'TASK-INTERACTION-STATE-CHANGE',
  //taskActivity = 'TASK-ACTIVITY',
  //taskEvent = 'TASK-EVENT',
  report = 'REPORT' //monitor or unmonitor for reporting events
}

export interface IFormData {
  id:string,
  entityType:string,
  records:Map<string,baseEntity>;
}

export interface baseEntity {
  obj_id?:string;
  obj_type?:string;
  obj_segment?:string;
  id?:string;
  name?:string;
  description?:string;
  
  createdDate?:number;
  lastUpdatedDate?:number;
  createdBy?:string;
  lastUpdatedBy?:string;
}

//used by the contact us form component
export interface contactInfo {
    firstName:string;
    lastName:string;
    companyName:string;
    language?:string;
    country?:string;
    question?:string;
    agreeToServiceAgreement:boolean;
    emailAddress:string;
    phoneNumber?:string;
    jobTitle?:string;
    interest?:string;
    customerQuery?:string;
}