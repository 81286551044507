<mat-drawer-container class="example-container">
    <mat-drawer mode="side" opened>
        
        <mat-nav-list>

            <mat-list-item aria-label="Dashboard">
                <a mat-list-item routerLink='/admin' routerLinkActive='list-item-active'> [Admin]</a>
            </mat-list-item>
        
            <mat-list-item aria-label="Analytics">
                <a mat-list-item routerLink='/admin/Tab1Component' routerLinkActive='list-item-active'> [Analytics]</a>
            </mat-list-item>
        
            <mat-list-item aria-label="Admin">
                <a mat-list-item routerLink='/admin/Tab2Component' routerLinkActive='list-item-active'>[Admin]</a>
            </mat-list-item>
        </mat-nav-list>


    </mat-drawer>
    <mat-drawer-content>

	<div class="tabManager">


		<div *ngFor="let task of tabs; let first = first " >
			<div class="tab" [class.selected]=task.active [class.unselected]=!task.active>
				<a class="flex-fill" [routerLink]="task.route.path + '/' + task.name" >
					<label  class="tab_label" >                                                     
						<span ></span> <!--class="taskType_{{task.task.taskType}} {{task.value.task.taskState}}"-->
							{{task.name}} 
						<span class="closeTab" [matMenuTriggerFor]="menu" aria-label="close tab"></span>                             <!--(click)="removeTask(task.value.id)"-->
						<mat-menu #menu="matMenu">
							<button mat-menu-item>
							<mat-icon>close</mat-icon>
							<span>Close</span>
						</button>
					</mat-menu>
					
				</label>
				</a>
				
			</div>
		</div>
		<div class="tabFiller">&nbsp;</div>
		<!-- All tabs
		<ul class="nav nav-tabs bg-light mt-3">
			<li class="nav-item" *ngFor="let tab of tabs; let first = first;" (mouseleave)="mouseOverTab(null)"
				(mouseenter)="mouseOverTab(tab)">
				<div class="nav-link" [class.active]="tab.active">
					<div class="d-flex flex-row cursor-pointer">
						<a class="flex-fill" [routerLink]="tab.route.path + '/' + tab.name" >{{ tab.name }}</a>
						<div class="transition-all-ease-250ms"
							[style.width]="currentHoverTabKey == tab.key ? 'auto': '0px'"
							[class.pl-3]="currentHoverTabKey == tab.key"
							[class.opacity-0]="currentHoverTabKey != tab.key"
							[class.invisible]="currentHoverTabKey != tab.key" *ngIf="tabs.length > 1"
							(click)="disposeTab(tab)">X</div>
					</div>
				</div>
			</li>
		</ul> -->
</div>
		<!-- Content of one tab (and all other non visible) -->
		<div class="tab-content">
			<ng-container *ngFor="let tab of tabs">
				<div class="tab-pane fade {{tab.name}}" [class.show]="tab.active" [class.active]="tab.active"
					[id]="tab.name" role="tabpanel">
					<!--  -->
					<ng-container *ngComponentOutlet="tab.component;"></ng-container>
				</div>
			</ng-container>
		</div>
	
    </mat-drawer-content>
  </mat-drawer-container>
