import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProductPriceInfo, ProductPriceType } from 'src/app/_models/product';

@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent implements OnChanges, AfterViewInit{

    selectedSubTab:number = 1;
    @Input() productPrices:Array<ProductPriceInfo> | undefined = [];
    @Output() pricing = new EventEmitter<{term:ProductPriceType, currency:string}>();
    @Input() currency:string = 'gbp';
    @Input() term:ProductPriceType = ProductPriceType.monthly;
    totalCost:number = 0;

    constructor(protected cd:ChangeDetectorRef){
       
    }
    ngAfterViewInit(): void {
        if(!this.productPrices || this.productPrices.length == 0)
            this.selectSubTab(2);
        else
            this.selectSubTab(2);

    }
    ngOnChanges(changes: SimpleChanges): void {
        this.setPrice(1);
        this.selectSubTab(1);
    }


    selectSubTab(i:number){
        this.selectedSubTab = i + 1;
        this.cd.detectChanges();
        this.setPrice(i);
    }

    selectedCurrencyChanged($event:any, index:number){
        console.log('currency changed', $event.srcElement.value, index);
        this.currency = $event.srcElement.value;
       this.setPrice(index);
    }

    setPrice(index:number){
        let myPricing = {currency:this.currency, term:ProductPriceType.monthly};

        if(this.productPrices && this.productPrices[index]){
            myPricing.term = (this.productPrices[index].term);
            const unitPrice = this.productPrices[index].unitPrices;
            console.debug('Unit Price is', unitPrice);
            if(unitPrice){
                let totalCost = unitPrice[myPricing.currency];
                if(totalCost){
                    totalCost = totalCost / 100;
                    this.totalCost = totalCost.toString();
                }
            }
        }
        

        this.pricing.emit(myPricing);
    }
}
