import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-corporate-navbar',
  templateUrl: './corporate-navbar.component.html',
  styleUrls: ['./corporate-navbar.component.scss']
})
export class CorporateNavbarComponent {

  isSticky: boolean = false;
    @Input() textColor:string = "white";

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 50;
  }
}
