import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Registration } from 'src/app/_models/registration';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {

    private apiURL: string = environment.api.schema + '://' + environment.api.host + ':' + environment.api.port + '/';
    private registrationPath = environment.api.paths.registration;

    constructor(private http: HttpClient) { }

    public register(reg: Registration): Observable<any> {
        try {
            if (!reg)
                throw new Error('Registration_Details_Missing');

             return this.http.post<any>(this.apiURL + this.registrationPath, reg, { headers: new HttpHeaders().set('Content-Type', 'application/json'), observe: 'response' })
            //     .pipe(
            //         catchError(this.httpError)
            //     )
                .pipe(map((res) => {
                    try {

                        console.log('http response', res);

                        const responseMessage: any = res.body;
                        const headers: HttpHeaders = res.headers;


                        return responseMessage;
                    } catch (err) {
                        console.error('Error creating registration', err);
                        throw (err);
                    }

                }));
        } catch (err) {
            console.error('Error Logging into iconic', err);
            throw err;
        }
    }

    public validate(regCode: string, regId: string): Observable<any> {
        try {
            if (!regCode || !regId)
                throw new Error('Registration_Details_Missing');

            return this.http.post<any>(this.apiURL + this.registrationPath + '/validate', { regCode, regId }, { headers: new HttpHeaders().set('Content-Type', 'application/json'), observe: 'response' })
                // .pipe(
                //     catchError(this.httpError)
                // )
                .pipe(map((res) => {
                    try {

                        console.log('http response', res);

                        const responseMessage: any = res.body;
                        const headers: HttpHeaders = res.headers;


                        return responseMessage;
                    } catch (err) {
                        console.error('Error creating registration', err);
                        throw (err);
                    }

                }));
        } catch (err) {
            console.error('Error Logging into iconic', err);
            throw err;
        }
    }

    public checkout(regId: string): Observable<any> {
        try {

            return this.http.put<any>(this.apiURL + 'subscribe', { regId }, { headers: new HttpHeaders().set('Content-Type', 'application/json'), observe: 'response' })
                .pipe(
                    catchError(this.httpError)
                )
                .pipe(map((res) => {
                    try {

                        console.log('http response', res);

                        const responseMessage: any = res.body;
                        const headers: HttpHeaders = res.headers;


                        return responseMessage;
                    } catch (err) {
                        console.error('Error creating registration', err);
                        throw (err);
                    }

                }));
        } catch (err) {
            console.error('Error Logging into iconic', err);
            throw err;
        }
    }

    public getCheckoutStatus(checkoutSessionId: string): Observable<any> {
        try {

            return this.http.get<any>(this.apiURL + 'subscribe?sessionId=' + checkoutSessionId, { headers: new HttpHeaders().set('Content-Type', 'application/json'), observe: 'response' })
                .pipe(
                    catchError(this.httpError)
                )
                .pipe(map((res) => {
                    try {

                        console.log('http response', res);

                        const responseMessage: any = res.body;
                        const headers: HttpHeaders = res.headers;


                        return responseMessage;
                    } catch (err) {
                        console.error('Error creating registration', err);
                        throw (err);
                    }

                }));
        } catch (err) {
            console.error('Error Logging into iconic', err);
            throw err;
        }
    }

    public provisionService(registrationId: string): Observable<any> {
        try {

            return this.http.post<any>(this.apiURL + 'registration/provision', { regId:registrationId }, { headers: new HttpHeaders().set('Content-Type', 'application/json'), observe: 'response' })
                .pipe(
                    catchError(this.httpError)
                )
                .pipe(map((res) => {
                    try {

                        console.log('http response', res);

                        const responseMessage: any = res.body;
                        const headers: HttpHeaders = res.headers;

                        return responseMessage;
                    } catch (err) {
                        console.error('Error creating registration', err);
                        throw (err);
                    }
                }));
        } catch (err) {
            console.error('Error Logging into iconic', err);
            throw err;
        }
    }

    private httpError(error: any) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error.serverError.name);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => new Error(error.error.serverError.name));
    }
}
