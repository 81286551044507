import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RegistrationService } from 'src/app/_services/registration.service';
import { LineOfBusinessService } from 'src/app/_services/line-of-business.service';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-validate-registration',
    templateUrl: './validate-registration.component.html',
    styleUrls: ['./validate-registration.component.scss']
})
export class ValidateRegistrationComponent implements OnInit {

    regId: string | undefined;
    regCode: string | undefined;
    isLoading: boolean = false;
    errorCode: string | undefined;
    registratonCodeError:string = ""

    constructor(private router: Router, private regService: RegistrationService, private lob: LineOfBusinessService, private route: ActivatedRoute) { }


    ngOnInit(): void {
        //get the product and display it's details
        this.route.params.subscribe(param => {
            this.regId = param['regId'];
            this.regCode = param['regCode'];

            if (!this.regId) {
                this.errorCode = 'No_RegId';
                return;
            }

            if (this.regId && this.regCode)
                this.verify();


        });
    }

    submit(form: NgForm) {
        //console.log(form);
        this.registratonCodeError = "";

        if (form.valid && this.regId) {
            this.regCode = form.value["regCode"];
            this.isLoading = true;
            this.verify();
        }else{
            this.registratonCodeError = "Please check your validation code and try again";
        }
    }


    verify() {
        try {
            if (!this.regCode || !this.regId)
                throw new Error('No_RegId');

            this.isLoading = true;
            //console.log(await this.http.post(url,registration).toPromise());
            this.regService.validate(this.regCode, this.regId).subscribe({
                next: (regResult) => {
                    //console.log('Registration Result', regResult);
                    this.router.navigate(['checkout/' + this.regId]);
                    this.isLoading = false;
                },
                error: (err) => {
                    this.registratonCodeError = 'validate-registration.' + err.error.serverError.name.toString();
                    this.isLoading = false;
                    //this.registratonCodeError = "Please check your validation code and try again";
                }
            });

        } catch (err) {
            this.isLoading = false;
            this.registratonCodeError = "Please check your validation code and try again";
        }
    }
}
