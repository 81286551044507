import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from 'src/app/_services/registration.service';
import { ProductDescription, ProductPriceType } from 'src/app/_models/product';
import { Registration } from 'src/app/_models/registration';
import { LineOfBusinessService } from 'src/app/_services/line-of-business.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-service-plan',
    templateUrl: './service-plan.component.html',
    styleUrls: ['./service-plan.component.scss']
})
export class ServicePlanComponent implements OnInit {

    productName: string = '';
    product: ProductDescription | undefined;
    isLoading: boolean = false;
    formError:string = "";
	productCurrency:string = 'gbp'; //the currency used by the subscription
	productTerm:ProductPriceType = ProductPriceType.monthly; //the payment term used by the subscription 
    language:string = 'en-GB'; //language to display product in
    price:string = '';
    showRegComplete = false;

    constructor(private router: Router, private regService: RegistrationService, private lob: LineOfBusinessService, private route: ActivatedRoute, private translate:TranslateService ) {
        this.translate.setDefaultLang('en-GB');
        this.translate.use('en-GB');
     }

    ngOnInit(): void {

        this.translate.get('service-plan.title').subscribe((res: string) => {
            console.log('Translation', res);
        });
        
        //get the product and display it's details
        this.route.params.subscribe(param => {
            this.productName = param['servicePlan'];
			this.productCurrency = param['currency'];
            this.productTerm = param['term'];

            if (this.productName){
                //default the currency and term
                if(!this.productCurrency)
                    this.productCurrency = 'gbp';

                if(!this.productTerm)
                    this.productTerm = ProductPriceType.monthly;

                this.getProduct();
            }
                
        });
    }

    async signUp(form: NgForm) {
        try {
            this.isLoading = true;
            console.log('Starting registration process ', form);
            this.formError = ""


            let registration: Registration = { request: "register", productId: this.product?.id, term:this.productTerm, currency: this.productCurrency };

            console.log('Registration request', registration);
            
            registration.firstName = form.value["firstName"];
            registration.lastName = form.value["lastName"];
            registration.companyName = form.value["companyName"];
            registration.phoneNumber = form.value["formattedPhoneNumber"];
            registration.email = form.value["emailAddress"];
            registration.jobTitle = form.value["jobTitle"];
            registration.language = form.value["language"];
            registration.country = form.value["country"];

            registration.agreeToServiceAgreement = form.value["agreeToServiceAgreement"];

            //console.log(await this.http.post(url,registration).toPromise());

            this.regService.register(registration).subscribe({
                next: regResult => {
                    //console.log('Registration Result', regResult);
                    this.isLoading = false;
                    this.router.navigate(['./register/validate/' + regResult.registrationId]);
                },
                error: err => {
                    console.error('Create Registration Error',err.error.serverError.name);
                    this.isLoading = false;
                    //if the error was a form error then display the error message on the form

                    this.formError = 'service-plan.' + err.error.serverError.name.toString();
                    switch(err.error.serverError.name){
                        case 'Company_Exists':
                            
                        break;
                        default:
                            //this.formError = "We were unable to process your request, please check the details and try again";
                            this.showRegComplete = true; 
                    } 
                    
                }
            });
        
        } catch (err) {
            this.isLoading = false;
            console.error('Registration Error', err);
        }

    }

    getProduct() {
        console.log('Service Plan: ' + this.productName);

        this.lob.getData('product', this.productName + '_'  + this.language).subscribe(p => {
            console.log('Service Plan: Got product', p);

            if(p && p.length == 1)
                this.product = p[0];

            
        });
    }

    setTerm($event:any){
        console.log('Term updated', $event);
        this.productTerm = $event.term;
        this.productCurrency = $event.currency;
        
        const unitPrice = this.product?.prices.find(x => (x.term == this.productTerm));
        if(unitPrice){
            let price = unitPrice.unitPrices[this.productCurrency];

            if(price){
                price = price / 100;
                this.price = price.toString();
            }
                
        }
    }


  
}