import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { RegistrationService } from 'src/app/_services/registration.service';
import { ProductDescription } from 'src/app/_models/product';
import { LineOfBusinessService } from 'src/app/_services/line-of-business.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, AfterViewInit {

  product:ProductDescription | undefined;
  regId:string | undefined;
  errorCode:string | undefined;
  clientSecret:any;
  stripe:Stripe | null = null;
  checkoutTitle:string = "Enter your payment details";
  showRegComplete = false;
  
  @ViewChild("myCheckout") checkoutElement: HTMLElement | undefined;


  constructor(private router:Router, private regService:RegistrationService, 
    private lob:LineOfBusinessService, private route:ActivatedRoute) { }
  
  ngOnInit(): void {
    //get the product and display it's details

    this.loadStripeScript();


  }

  async ngAfterViewInit() {
    
    // if(!this.regId){
    //   this.errorCode = 'No_RegId';
    //   return;
    // }

    this.route.params.subscribe(async param => {
      this.regId = param['regId'];

      if(!this.regId){
        this.errorCode = 'No_RegId';
        return;
      }

      this.stripe = await loadStripe(environment.stripe.publicKey,{});
      
      this.regService.checkout(this.regId).subscribe({
        next: async clientSecret => {
            const myCheckout = await this.stripe?.initEmbeddedCheckout(clientSecret);
            myCheckout?.mount("#myCheckout");
            //console.log('myCheckout', myCheckout);
          },
        error: (err) => {
            console.error(console.error('Error getting stripe checkout', err));
            this.handleCheckoutError(err);
        }
      });
      
      
      
    });

    


    // this.regService.checkout(this.regId).subscribe(async clientSecret => {
    //   const mySecret = {"clientSecret":"cs_test_b12a5PjdL8JaC0E5xpJMkdiJSAOAWHCBv6VPIjF6Os4IIm1bDsPEgmwno1_secret_fidwbEhqYWAnPydgaGdgYWFgYScpJ2lkfGpwcVF8dWAnPydocGlxbFpscWBoJyknd2BhbHdgZnFKa0ZqaHVpYHFsamsnPydkaXJkfHYneCUl"}
    //   const myCheckout = await this.stripe?.initEmbeddedCheckout(clientSecret);
    //   myCheckout?.mount(this.checkoutElement!);

    // });
  }

  private handleCheckoutError(errCode:string){
    switch(errCode){
        case '':
        break;
        default:
            this.showRegComplete = true;
            this.checkoutTitle = "Thank you for registering with iconic.cx";

    }
  }

  private loadStripeScript(){
    
    
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.src = "https://js.stripe.com/v3/";
    document.body.appendChild(chatScript);
  }
}
