import { Component } from '@angular/core';

@Component({
  selector: 'app-application-external',
  templateUrl: './application-external.component.html',
  styleUrls: ['./application-external.component.scss']
})
export class ApplicationExternalComponent {

}
