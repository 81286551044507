
<h1 class="fw-bold fs-6 mb-3">
    {{products.title}}<!-- Pick the perfect plan for you -->
  </h1>
  <p class="mb-6 text-secondary">
    {{products.header}}
    <!-- Get the best of iconic cx™ with simple, transparent pricing and no surprise fees. Grow your business on our platform and omly pay for what you use. -->
  </p>
  

  <div class="pricing-table">
    <div class="ptable-item" [ngClass]="p.className" *ngFor="let p of products.productList">
      <div class="ptable-single">
        <div class="ptable-header">
          <div class="ptable-status" *ngIf="p.className">
            <span>Hot</span>
          </div>
          <div class="ptable-title" >
            <h2>{{p.displayName}}</h2>
          </div>
          <div class="ptable-price">
            <h2><small>{{p.currency}}</small>{{p.price}}<span>{{p.term}}</span></h2>
          </div>
        </div>
        <div class="ptable-body">
          <div class="ptable-description">
            <ul>
              <li *ngFor="let f of p.features" class="active">{{f.title}}</li>
            </ul>
          </div>
        </div>
        <div class="ptable-footer">
          <div class="ptable-action">
            <a routerLink="/register/service/{{p.name}}"  class="btn btn-warning me-3 btn-lg">{{products.actionName}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
