import { baseEntity } from "./core";

export interface ProductInfo {
    displayName:string;
    description:string;
    language:string;
    features:ProductFeature[];
    defaultProduct:boolean;
    solutionName:string;
    prices:ProductPriceInfo[];
    id:string // sk of the product [name and language]
}

export interface ProductPriceInfo {
    unitPrices:any;
    billingScheme:BillingScheme;
    term:ProductPriceType;
}

export interface ProductDescription extends ProductInfo {
    displayName:string;
    description:string;
    language:string;
    features:ProductFeature[];
    defaultProduct:boolean;
    
}

export interface ProductFeature extends baseEntity{
    keyFeature:boolean;
    featuredValue:string;
}

export interface ProductSolution extends ProductDescription {
    heroStatement:string[];
    backgroundImage:string;
}

export interface SolutionFeature {
    title:string;
    header:string;
    image:string;
    FeatureList:ProductFeature[];
    displayItems:SolutionItemDisplay // column, row
    footer:string;
}

export enum SolutionItemDisplay {
    COLUMN = "solumn",
    ROW = "row"
}

export enum ProductPriceType {
    one_time,
    monthly,
    annual
}

export enum BillingScheme {
    per_unit = 'per_unit'
}