
<section class="pt-7">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6 text-md-start text-center py-6">
                <h1 class="mb-4 fs-9 fw-bold">Accelerate Transformation</h1>
                <p class="mb-6 lead text-secondary">
                    Balance quality objectives and remove<br class="d-none d-xl-block" />
                    operational constraints. Get the insights<br class="d-none d-xl-block" />
                    you need to achieve your desired outcomes.</p>
                <div class="text-center text-md-start">
                    <a class="btn btn-warning me-3 btn-lg" routerLink="/contactus" role="button">Request a demo</a>
                    <a class="btn btn-link text-warning fw-medium" routerLink="/contactus" role="button">
                        <span class="fas fa-play me-2"></span>Contact Sales</a>
                </div>
            </div>
            <div class="col-md-6 text-end"><img class="pt-7 pt-md-0 img-fluid" src="assets/img/hero/Cloud-platform.png"
                    alt="" /></div>
        </div>
    </div>
</section>




      <!-- ============================================-->
      <!-- <section> begin ============================-->
        <section class="pt-5" id="validation">

            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <h5 class="text-secondary">Scalable,Secure & Optimised</h5>
                  <h2 class="mb-2 fs-7 fw-bold">Cloud Platform</h2>
                  <p class="mb-4 fw-medium text-secondary">
                    A single, simple to use yet sophisticated platform 
                    allowing you to manage all your customer interactions 
                    across their entire journey.
                  </p>
                  <h4 class="fs-1 fw-bold">Machine Learning</h4>
                  <p class="mb-4 fw-medium text-secondary">Maximise the raw data generated by every customer interaction,<br />and use them to continually improve your processes</p>
                  <h4 class="fs-1 fw-bold">Omni-Channel Interactions</h4>
                  <p class="mb-4 fw-medium text-secondary">A true 360 view of your customer across all their interactions while providing<br />a frictionless customer journey.</p>
                  <h4 class="fs-1 fw-bold">Advanced Security</h4>
                  <p class="mb-4 fw-medium text-secondary">With advanced encryption in transit and at rest<br />our enterprise grade security allows you to work from anywhere.</p>
                </div>
                <div class="col-lg-6"><img class="img-fluid" src="assets/img/validation/validation.png" alt="" /></div>
              </div>
            </div><!-- end of .container-->
    
          </section>
          <!-- <section> close ============================-->
          <!-- ============================================-->
    
    
    
    
          <!-- ============================================-->
          <!-- <section> begin ============================-->
          <section class="pt-5" id="manager">
    
            <div class="container">
              <div class="row">
                <div class="col-lg-6"><img class="img-fluid" src="assets/img/manager/manager.png" alt="" /></div>
                <div class="col-lg-6">
                  <h5 class="text-secondary">Decision making made easy</h5>
                  <p class="fs-7 fw-bold mb-2">Advanced analytics</p>
                  <p class="mb-4 fw-medium text-secondary">
                    Transform your business data into information, take decisive actions to meet your targets and objectives. Intimately understand the needs of your customer.
                  </p>
                  <div class="d-flex align-items-center mb-3"> <img class="me-sm-4 me-2" src="assets/img/manager/tick.png" width="35" alt="tick" />
                    <p class="fw-medium mb-0 text-secondary">Clear and simple dashboards with a wide ranging set of real-time and historical statistics.</p>
                  </div>
                  <div class="d-flex align-items-center mb-3"> <img class="me-sm-4 me-2" src="assets/img/manager/tick.png" width="35" alt="tick" />
                    <p class="fw-medium mb-0 text-secondary">Streamline your data ingestion and management with automated, secture ETL and data sharing</p>
                  </div>
                  <div class="d-flex align-items-center mb-3"><img class="me-sm-4 me-2" src="assets/img/manager/tick.png" width="35" alt="tick" />
                    <p class="fw-medium mb-0 text-secondary"> Analyse each customer interaction for words that suggest emotional triggers and respond in real-tim.</p>
                  </div>
                </div>
              </div>
            </div><!-- end of .container-->
    
          </section>
          <!-- <section> close ============================-->
          <!-- ============================================-->
    
    
    
    
          <!-- ============================================-->
          <!-- <section> begin ============================-->
          <section class="pt-5" id="marketer">
    
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <h5 class="text-secondary">Deliver Engaging</h5>
                  <p class="mb-2 fs-8 fw-bold">Customer experiences</p>
                  <p class="mb-4 fw-medium text-secondary">  </p>
                  <h4 class="fw-bold fs-1">Webchat</h4>
                  <p class="mb-4 fw-medium text-secondary">Our chat solution allows you to start simple and leverage powerful AI features as you grow.</p>
                  <h4 class="fw-bold fs-1">Email</h4>
                  <p class="mb-4 fw-medium text-secondary">Manage inbound and outbound emails with ease and keep on top of your customer's requests.</p>
                  <h4 class="fw-bold fs-1">Voice</h4>
                  <p class="mb-4 fw-medium text-secondary">Our fully featured cloud PBX provides advanced routing and an AI powered conversational engine.</p>
                </div>
                <div class="col-lg-6"><img class="img-fluid" src="assets/img/marketer/marketer.png" alt="" /></div>
              </div>
            </div><!-- end of .container-->
    
          </section>
          <!-- <section> close ============================-->
          <!-- ============================================-->

          



    <!-- ============================================-->
    <!-- <section> begin ============================-->
        <section class="pt-5" id="marketing">

            <div class="container">
                <h1 class="fw-bold fs-6 mb-3">A Solution That's Right For You</h1>
                <p class="mb-6 text-secondary">Join 100s of other businesses who us our platform to grow</p>
                <div class="row">
                    <div class="col-md-4 mb-4">
                        <div class="card"><img class="card-img-top" src="assets/img/marketing/marketing01.png" alt="" />
                            <div class="card-body ps-0">
                                <h3 class="fw-bold"><a class="fw-bold text-decoration-none me-1"
                                        routerLink="/solutions/smallbusiness">For Small Business</a></h3>
                                <p class="text-secondary">Start delighting your customers today. It's quicks and easily, and
                                    there are no hidden fees</p>
    
                                    <div class="text-center"><a class="btn btn-warning" routerLink="/solutions/smallbusiness" role="button">Register Free</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="card"><img class="card-img-top" src="assets/img/marketing/marketing02.png" alt="" />
                            <div class="card-body ps-0">
                                <h3 class="fw-bold"><a class="fw-bold text-decoration-none me-1"
                                        routerLink="/solutions/mediumenterprise">For Medium Enterprises</a></h3>
                                <p class="text-secondary">Stand out, be unique and exceed your customer expectations. Build
                                    a brand your customers will love</p>
    
                                    <div class="text-center"><a class="btn btn-warning" routerLink="/solutions/mediumenterprise" role="button">Register Free</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="card"><img class="card-img-top" src="assets/img/marketing/marketing03.png" alt="" />
                            <div class="card-body ps-0">
                                <h3 class="fw-bold"><a class="fw-bold text-decoration-none me-1"
                                        routerLink="/solutions/largeorganisation">For Larger Organisations</a></h3>
                                <p class="text-secondary">Create iconic customer experiences across all your customer
                                    touchpoints and be available to your customers 24/7</p>
    
                                    <div class="text-center"><a class="btn btn-warning" routerLink="/solutions/largeorganisation" role="button">Register Free</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- end of .container-->
    
        </section>
        <!-- <section> close ============================-->
        <!-- ============================================-->
    