import {HttpClient} from "@angular/common/http";
import {TranslateLoader} from "@ngx-translate/core";
import {Observable} from 'rxjs';
import { environment } from "../../environments/environment";


export class IconicHTTPTranslateLoader implements TranslateLoader {
    
  constructor(private http: HttpClient,
                public prefix: string = "lang=", 
                public module: string = "iconic-cx-corporate-website") {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    try{
      //console.log('Getting translations...');
      const url = environment.api.schema + '://' + environment.api.host + '/' + environment.api.paths.translate + '?';
      this.module = '&module=' + this.module;

      return this.http.get(`${url}${this.prefix}${lang}${this.module}`);
    }catch(err){
      console.error('Error getting translations', err);
      throw err
    }
  }
}