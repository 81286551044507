<br/>

<div class="container">
    
    <h1>
        {{ 'service-plan.title' | translate}}
    </h1>
    <br/>
    <div class="row align-items-top">
        <div class="col-lg-6 col-md-12">

            <div class="col-md-12 mb-5 mb-md-0">
                <div class="card card-span bg-primary h-100">
                  <div class="card-body p-4">
                    <div class="d-flex justify-content-between"><img class="mb-3" src="assets/img/icons/rocket.png" height="80" alt="..." /><img src="assets/img/icons/clouds-1.png" height="90" alt="..." /></div>
                    <h5 class="text-light">{{product?.displayName}}</h5>
                    
                        <ul class="text-light lh-lg">
                            <li *ngFor="let f of product?.features" >{{f.featuredValue}} {{f.name}}</li>
                        </ul>
                    
                    <div class="text-end">
                        <app-product-price (pricing)="setTerm($event)" [productPrices]="product?.prices"></app-product-price>
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <br/>
            <div class="registrationOverview">
                <h5>Get your cloud contact center up and running in minutes.</h5>
                <p>Boost productivity with our out-of-the-box CRM and help desk integrations and scale in a matter of clicks.</p>
            </div>
            <br/>
            
            <div class="registrationHelp"> 
                <h5>Got a question?</h5>
                <p>Get in touch with one of our CX Specialists who will be able to help you become iconic</p>
            </div>
        </div>
        <div  *ngIf="showRegComplete === false" class="col-lg-6 col-md-12 registration">
            
            <app-contact-form [showTerms]="true" [formError]="formError" [isLoading]=isLoading formTitle="Answer a few questions to get started" heading="" submitBtnTxt="Register" (formData)="signUp($event)"></app-contact-form>

        </div>

        <div  *ngIf="showRegComplete" class="col-lg-6 col-md-12 registration">
            
            <h2>Thank you for registering with us</h2>
            
            <p>One of our CX Experts will be in-touch.</p>

        </div>

    </div>
  
</div>