import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CardMenuData } from 'src/app/_models/UI';

@Component({
  selector: 'app-menu-item-card',
  templateUrl: './menu-item-card.component.html',
  styleUrls: ['./menu-item-card.component.scss']
})
export class MenuItemCardComponent implements OnInit {

  @Input() data: CardMenuData | undefined;

  
  constructor(private router:Router) {
   }

  ngOnInit(): void {
  }

  navigate(link: string = ""){
    console.log('Report link is ' + link);
    this.router.navigate(['./analytics/' + link])

  }
}
