import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';


import { isValidEmail } from 'src/app/_helpers/utility-functions';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent {

    constructor() { }

    @Input() showTerms:boolean = false;
    @Input() showCustomerInterest:boolean = false;
    @Input() showCountry:boolean = false;
    @Input() showLangugage:boolean = false;

    @Output() formData = new EventEmitter<NgForm>();
    @Input() formTitle:string = '';
    @Input() heading:string = '';
    @Input() submitBtnTxt:string = 'Submit';
    @Input() formSubmitted:string = '';
    
    @Input() formError:string = '';
    @Input() isLoading: boolean = false;
    
    formSaved:boolean = false;
    countryCodeName:string = '';
    formattedNumber:string = "";
    

        public countryCodeChanged($event:any){
            console.log($event);
            console.log($event.srcElement.selectedOptions[0].attributes['data-countrycode'].nodeValue);

            this.countryCodeName = $event.srcElement.selectedOptions[0].attributes['data-countrycode'].nodeValue
        }
    
    async validate(form: NgForm) {
        

        try {
            this.formError = ""

            //console.log(form.valid);

            const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
            

            const phoneNum =  form.value['phoneNumber'];
            

            try{
                const number = phoneUtil.parseAndKeepRawInput(phoneNum, this.countryCodeName);
          
                if(phoneUtil.isValidNumberForRegion(number, this.countryCodeName)){
                    this.formattedNumber = phoneUtil.format(number, PhoneNumberFormat.E164);
                }else{
                    this.formError = "Phone number format is incorrect";
                    this.formattedNumber = '';
                    return;
                }
            }catch(err){
                this.formError = "Phone number format is incorrect";
                this.formattedNumber = '';
                
                return
            }

            

            if(!isValidEmail(form.value["emailAddress"])){
                this.formError = "Please check your email address";
                return;
            }

            if(this.showTerms && !form.value["agreeToServiceAgreement"]){
                //please agree to out terms
                //this.formError = "agreeToServiceAgreement";
                this.formError = "Please agree to our terms and conditions";
                return;
            }

            if (!form.valid) {
                //check the form validity
                //this.formError = "CompleteRegistrationForm";
                this.formError = "Please complete all required fields";
                return;
            }



            this.formData.emit(form);

        }catch(err){
            console.log('Form Error', err);
            this.formError = 'Error occured';
        }
    }
}
