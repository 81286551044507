import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { TitleCasePipe } from '@angular/common';


import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper'; 
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatSelectModule} from '@angular/material/select'; 
import {MatTableModule} from '@angular/material/table'; 
import {MatChipsModule} from '@angular/material/chips'; 
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu'; 
import {MatIconModule} from '@angular/material/icon'; 
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs'; 
import {MatDialogModule} from '@angular/material/dialog'; 
import {MatTooltipModule} from '@angular/material/tooltip'; 


import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Tab1Component } from './tabs/tab1/tab1.component';
import { Tab2Component } from './tabs/tab2/tab2.component';
import { Tab0Component } from './tabs/tab0/tab0.component';
import { HomeTabComponent } from './tabs/home-tab/home-tab.component';
import { MenuComponent } from './tabs/menu/menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HighchartsChartModule } from 'highcharts-angular';



import { ApplicationExternalComponent } from './layouts/application/application-external/application-external.component';
import { GenericTableComponent } from './_components/general/generic-table/generic-table.component';


import { JwtModule } from '@auth0/angular-jwt';
import { HomepageComponent } from './layouts/corporate/homepage/homepage.component';
import { CorporateNavbarComponent } from './_components/general/corporate-navbar/corporate-navbar.component';
import { HomeComponent } from './pages/corporate/home/home.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { HttpRequestInterceptor } from './_helpers/http.interceptor';


import { MenuItemCardComponent } from './_components/general/menu-item-card/menu-item-card.component';
import { TaskIconComponent } from './_components/general/task-icon/task-icon.component';
import {CorporateFooterComponent } from './_components/general/corporate-footer/corporate-footer.component';
import { FeatureComponent } from './layouts/corporate/feature/feature.component';
import { FeatureDetailComponent } from './layouts/corporate/feature-detail/feature-detail.component';
import { ServicePlanComponent } from './pages/corporate/service-plan/service-plan.component';
import { ProductListComponent } from './_components/general/product-list/product-list.component';
import { FaqComponent } from './_components/general/faq/faq.component';
import { ProductFeatureTableComponent } from './_components/general/product-feature-table/product-feature-table.component';
import { ValidateRegistrationComponent } from './pages/corporate/validate-registration/validate-registration.component';
import { CheckoutComponent } from './pages/corporate/checkout/checkout.component';
import { CheckoutCompleteComponent } from './pages/corporate/checkout-complete/checkout-complete.component';
import { ProductRegistrationComponent } from './layouts/corporate/product-registration/product-registration.component';
import { LoadingComponent } from './_components/general/loading/loading.component';
import { PhoneNumberInputComponent } from './_components/general/phone-number-input/phone-number-input.component';
import { ProductPriceComponent } from './_components/general/product-price/product-price.component';

import { IconicHTTPTranslateLoader } from './_helpers/iconicHTTPTranslationLoader';
//import { IconicMissingTranslationHandler } from './_helpers/iconicMissingTranslationHandler';


import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TermsAndConditionsComponent } from './pages/corporate/terms-and-conditions/terms-and-conditions.component';
import { ContactUsComponent } from './pages/corporate/contact-us/contact-us.component';
import { ContactFormComponent } from './_components/general/contact-form/contact-form.component';
import { PlatformComponent } from './pages/corporate/platform/platform.component';

@NgModule({
  declarations: [
    AppComponent,
    Tab1Component,
    Tab2Component,
    Tab0Component,
    HomeTabComponent,
    MenuComponent,
    ApplicationExternalComponent,
    GenericTableComponent,
  
    HomepageComponent,
    CorporateNavbarComponent,
    HomeComponent,
 
    MenuItemCardComponent,
 
    TaskIconComponent,
    CorporateFooterComponent,
  
    FeatureComponent,
    FeatureDetailComponent,
    ServicePlanComponent,
    ProductListComponent,
    FaqComponent,
    ProductFeatureTableComponent,
    ValidateRegistrationComponent,
    CheckoutComponent,
    CheckoutCompleteComponent,
    ProductRegistrationComponent,
    LoadingComponent,
    PhoneNumberInputComponent,
    ProductPriceComponent,
    TermsAndConditionsComponent,
    ContactUsComponent,
    ContactFormComponent,
    PlatformComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    BrowserAnimationsModule,
    HttpClientModule,

    HighchartsChartModule,
    
    MatDialogModule,
    ReactiveFormsModule, 
    FormsModule,

    MatButtonModule, 
    MatFormFieldModule, 
    MatInputModule, 
    MatStepperModule, 
    MatSidenavModule, 
    MatListModule,
    MatCheckboxModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatTableModule,
    MatChipsModule,
    MatCardModule,
    MatToolbarModule,
    MatTabsModule,
    MatTooltipModule,

    
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('id-token');
        }
      }
    }),

        // ngx-translate and the loader module
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: navigator.language,
            //missingTranslationHandler: {provide: MissingTranslationHandler, useClass: IconicMissingTranslationHandler},
            useDefaultLang: false

        })
],
  providers: [
    DatePipe,
    TitleCasePipe,

    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
   {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}, 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

function initializeApp(): Promise<any> {
  //get the configuration and translations for this organisation
  try{
    console.log('Application configutation started...');

  
    console.log('Application configutation complete');
  }catch(err){
    console.error('Error initialising application', err);
    return Promise.reject(err);
  }
  return Promise.resolve(true);
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): IconicHTTPTranslateLoader {
  let loader:IconicHTTPTranslateLoader;
  try{
    loader = new IconicHTTPTranslateLoader(http);
    return loader;
  }catch(err){
    console.error('Error loading translations', err);
    throw err;
  }
}