export const environment = {
    stripe: {
        publicKey: 'pk_test_51OU8wYJnzczH3RWANvjLkg6nN4kD5fxpFQsdi2GnAYKJpwU1uhp8MWmjkjLGecH7JuiLfGTpTPOwb60zTSgtJci400TLLI9uMl'
    },
    api: {
        host: 'api.staging.iconic.cx',
        port: '443',
        schema: 'https',
        
        paths:{
            translate: 'translate',
            login: 'auth/login',
            logout: 'auth/logout',
            pwdResetPath: 'auth/pwdReset',
            report: 'analytics',
            setChannelState: 'userChannelState',
            confirmTaskAllocation: 'task/allocate',
            getUserTasks: 'task',
            rejectTask: 'task/action',
            sendChatMessage: 'task/chat/message'   ,
            registration: 'registration',
            crmDataPath: 'crm'             
               
        },
        admin: {
            register: 'admin/user',
            user: 'admin/user',
            userService: 'admin/user/service',
            userGroup: 'admin/groups',
            userChannel: 'admin/user/channel',
            service: 'admin/services',
            serviceChannel: 'admin/services/channel'
        }
    },
    userSession: {
        scheme: 'wss',
        host: '2jsz404r24.execute-api.eu-west-2.amazonaws.com',
        port: '443',
        path: 'Prod'  
      },
    reporting : {
        route: 'report',
        scheme: 'wss',
        host:'kard4sjm98.execute-api.eu-west-2.amazonaws.com',
        path: 'Prod',
        port: '443'
      },
};
