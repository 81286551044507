<mat-card>
    <div class="container">
      <div fxLayout="row">
  
        <div fxLayout="column" fxFlex="50%" fxFlex.sm="100%" fxLayout.sm="row">
          <h3>{{data?.title}}</h3>
        </div>
  
        <div fxLayout="column" fxFlex="50%" fxFlex.sm="100%" fxLayout.sm="row">
          <ng-container *ngIf="data?.image; else fallBackTextImage">
            <div class="menu-item-image">
              <img src="{{data?.image}}" alt="">
            </div>
          </ng-container>
  
          <ng-template #fallBackTextImage>
            <div class="menu-item-image">
              <mat-icon>{{data?.fallbackTextImage}}</mat-icon>
            </div>
          </ng-template>
  
        </div>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row">
        <div fxLayout="column" fxFlex="100%">
          <p>{{data?.description}}</p>
          <button style="margin-top:2rem;" mat-raised-button color="primary" (click)="navigate(data?.link)" >{{data?.linkLabel}} </button>
          
        </div>
      </div>
    </div>
  </mat-card>
  
  