
<table mat-table [dataSource]="dataSource">

    <ng-container *ngFor="let column of columnsToDisplay" matColumnDef={{column}}>
        <th mat-header-cell *matHeaderCellDef>{{column}}</th>
    
    
        <td mat-cell *matCellDef="let row">
            
            <a (click)="displayEntity(row)" *ngIf="column === itemId">
                <mat-icon>edit</mat-icon>
            </a>
            
            <label *ngIf="column !== itemId">{{row[column]}}</label>
            
        </td>       
      </ng-container>
    
     <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
     <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>