import { Route } from "@angular/router";
import { Subject } from "rxjs";
import { ComponentRef } from "@angular/core";

export interface Tab {
   // data?: TaskInstance;
    name: string;
    component: any;
    componentRef?:ComponentRef<BaseTabComponent>;
    active: boolean;
    param?: any;
    key: string;
    url:string;
    $active:Subject<boolean>;
  }

  export interface CardMenuData {
    title: string;
    image: string;
    fallbackTextImage: string;
    description: string;
    link: string;
    linkLabel: string;
  }

  export interface BaseTabComponent {
    tab: Tab;
  }

  export enum AppLayoutType {
    Default = 'default',
    CorporateCheckout = 'app-checkout',
    AppExternal = 'app-external',
    Admin = 'admin', // admin layout
    CorporateHome = 'corporate-home',
    CorporateFeature = 'app-feature',
    CorporateFeatureDetail = 'app-feature-detail'
  }

  export enum AppError {
    NoTaskFound = 'No_Task_Found'
  }