import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-feature-table',
  templateUrl: './product-feature-table.component.html',
  styleUrls: ['./product-feature-table.component.scss']
})
export class ProductFeatureTableComponent {
  @Input() productFeatures:any;
  @Input() products:any;

}
