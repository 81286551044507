import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import solutionSet from './solution';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent implements OnInit {

  solutionName:string | null = '';
	solution:any;

  constructor(private route: ActivatedRoute) { }


  ngOnInit(): void {
		this.route.paramMap.subscribe(params => {
      
			this.solutionName = params.get('businessType');
			console.debug(['solution name ', params, this.solutionName, solutionSet])
			this.displaySolution();
		});
    }


  private displaySolution(){
		try{
			this.solution = solutionSet.solutions.find(s => {return this.solutionName == s.name});
      console.debug(this.solution);

			if(!this.solution)
			{
				//navigate to solution page
			}
			
		}catch(err){
			console.error('unable to display solution', err);
		}
	}
}
