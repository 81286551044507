<div class="container">
    <h1>Contact Us</h1>
        
    <div class="subCardContainer">
        <a class="card1">
            <h3>Chat To Sales</h3>
            <p class="small">Use our webchat to talk to a member of our sales team.</p>
            <div class="go-corner">
                <div class="go-arrow">
                <i class="lnr lnr-bubble"></i>
                </div>
            </div>
            </a>

            <a class="card1">
            <h3>Got a Query</h3>
            <p class="small">Fill out our contact form and a member of our team will get back to you.</p>
            <div class="go-corner">
                <div class="go-arrow">
                <i class="lnr lnr-pencil"></i>
                </div>
            </div>
            </a>

            <a class="card1">
            <h3>Support Question</h3>
            <p class="small">Need some help, login to your Service Desk account and request support.</p>
            <div class="go-corner">
                <div class="go-arrow">
                <i class="lnr lnr-cog"></i>
                </div>
            </div>
            </a>
    </div>
          
    <div class="slideContainer" [ngClass]="rightPanelActive" id="container">
        <div class="form-container sign-up-container">
    
        <form>
            <div>
                <h1 class="black">Contact Details</h1>
                <p>Get in touch by email or webchat</p>
            </div>
            <div class="textToLeft">
                <div style="display: flex; flex-flow: row;">
                    <div class="emailContact col-md-4">
                        <h5>Sales</h5>
                            <a href="mailto:sale@iconic.cx">sales@iconic.cx</a>
                    </div>
                    <div class="emailContact col-md-4">
                        <h5>Billing</h5>
                            <a href="mailto:billing@iconic.cx">billing@iconic.cx</a>
                    </div>
                    <div class="emailContact col-md-4">
                        <h5>Support</h5>
                            <a href="mailto:support@iconic.cx">support@iconic.cx</a>
                    </div>
                </div>
                <br/>
                <br/>
                <div>
                    <h5>London Office</h5>
                    <p>86 - 90 Paul Street</p>
                    <p>London</p>
                    <p>EC2A 4NE</p>
                </div>
            </div>
            
        </form>
    
            
        </div>
        <div class="form-container sign-in-container">
          
            <app-contact-form [showCustomerInterest]=true [formSubmitted]="formSubmitted" [formError]="formError" formTitle="Get in touch"  (formData)="sendContact($event)"></app-contact-form>
    
        </div>
        <div class="overlay-container">
          <div class="overlay">
            <div class="overlay-panel overlay-left">
              <h1>Contact Form</h1>
              <p>Click the link below to complete our contact form and a member or our team will be in touch with you</p>
              <a class="btn btn-outline-primary" (click)="toggleContainerClass()" id="signIn">Contact Form</a>
            </div>
            <div class="overlay-panel overlay-right">
                <h1>Want to get in touch</h1>
                
                <div>
                    <p>Need a quote or have a question?</p><br/>
                    <p>
                        Click the link below to contact a CX expert who will be happy to assist you.
                    </p></div>
                
                <a class="btn btn-outline-primary" (click)="toggleContainerClass()" ><span>Our Contact Details</span></a>
          </div>
        </div>
      </div>

    </div>

</div>