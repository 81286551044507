import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeTabComponent } from './tabs/home-tab/home-tab.component';
import { MenuComponent } from './tabs/menu/menu.component';
import { AppLayoutType } from './_models/UI';
import { FeatureComponent } from './layouts/corporate/feature/feature.component';
import { FeatureDetailComponent } from './layouts/corporate/feature-detail/feature-detail.component';
import { ServicePlanComponent } from './pages/corporate/service-plan/service-plan.component';
import { ValidateRegistrationComponent } from './pages/corporate/validate-registration/validate-registration.component';
import { CheckoutComponent } from './pages/corporate/checkout/checkout.component';
import { CheckoutCompleteComponent } from './pages/corporate/checkout-complete/checkout-complete.component';
import { TermsAndConditionsComponent } from './pages/corporate/terms-and-conditions/terms-and-conditions.component';
import { ContactUsComponent } from './pages/corporate/contact-us/contact-us.component';
import { PlatformComponent } from './pages/corporate/platform/platform.component';

const routes: Routes = [

    //checkout
    {
        path: 'checkout/:regId', component: CheckoutComponent,
        data: {
            layout: AppLayoutType.CorporateCheckout
        }
    },
    {
        path: 'checkout/success/:checkoutSessionId', component: CheckoutCompleteComponent,
        data: {
            layout: AppLayoutType.CorporateCheckout
        }
    },
    {
        path: 'register/service/:servicePlan', component: ServicePlanComponent,
        data: {
            layout: AppLayoutType.CorporateCheckout
        }
    },
    {
        path: 'register/validate/:regId', component: ValidateRegistrationComponent,
        data: {
            layout: AppLayoutType.CorporateCheckout
        }
    },
    {
        path: 'register/validate/:regId/:regCode', component: ValidateRegistrationComponent,
        data: {
            layout: AppLayoutType.CorporateCheckout
        }
    },
    {
        path: 'register', component: HomeTabComponent,
        data: {
            layout: AppLayoutType.CorporateCheckout
        }
    },
   
    //Marketing pages
    {
        path: 'platform', component: PlatformComponent,
        data: {
            layout: AppLayoutType.AppExternal
        }
    },
    {
        path: 'solutions', component: FeatureComponent,
        data: {
            layout: AppLayoutType.CorporateHome,
        }
    },
    {
        path: 'services', component: FeatureComponent,
        data: {
            layout: AppLayoutType.AppExternal,
        }
    },
    {
        path: 'contactus', component: ContactUsComponent,
        data: {
            layout: AppLayoutType.AppExternal,
        }
    },

    {
        path: 'platform/:feature', component: FeatureComponent,
        data: {
            layout: AppLayoutType.CorporateFeatureDetail
        }
    },
    {
        path: 'solutions/:businessType', component: FeatureComponent,
        data: {
            layout: AppLayoutType.AppExternal
        }
    },
    {
        path: 'whyiconic', component: FeatureComponent,
        data: {
            layout: AppLayoutType.AppExternal
        }
    },

    //Terms & Conditions
    {
        path: 'terms-and-conditions/:policyDocument', component: TermsAndConditionsComponent,
        data: {
            layout: AppLayoutType.AppExternal
        }
    },
    {
        path: 'terms-and-conditions', component: TermsAndConditionsComponent,
        data: {
            layout: AppLayoutType.AppExternal
        }
    },
  
    {
        path: '', component: HomeTabComponent, pathMatch: 'full',
        data: {
            layout: AppLayoutType.CorporateHome
        }
    }



];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
