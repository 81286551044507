<div class="bg-blur sticky-top">
    <nav class="navbar navbar-expand-lg navbar-light sticky-top" data-navbar-on-scroll="data-navbar-on-scroll">
        <div class="container">
            <!-- <a class="navbar-brand" href="index.html">
                <img src="assets/img/logo.svg" height="31" alt="logo" />
            </a> -->
    
            <a routerLink="/" class="iconic-logo">
                iconic.cx
            </a><span class="spacer"></span>
    
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"> </span></button>
          <div class="collapse navbar-collapse border-top border-lg-0 mt-4 mt-lg-0" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item"><a class="nav-link" aria-current="page" routerLink="/platform">Platform</a></li>
              <li class="nav-item"><a class="nav-link" aria-current="page" routerLink="/solution">Solutions</a></li>
              <li class="nav-item"><a class="nav-link" aria-current="page" routerLink="/services">Services</a></li>
              <li class="nav-item"><a class="nav-link" aria-current="page" routerLink="/contactus">Contact Us</a></li>
            </ul>
            <div class="d-flex ms-lg-4">
                <!-- <a class="btn btn-secondary-outline" href="#!">Sign In</a> -->
                <a class="btn btn-primary ms-3" routerLink="/register/service/cxFusionTrial">Try For Free</a>
            </div>
          </div>
        </div>
      </nav>
</div>
