export default {
    solutions: [
        {
            name:'smallbusiness',
            language: 'en-gb',
            backgroundImage: 'assets/img/small-business-header.jpg',
            heroStatements : [
                {
                    sentance: '<span class="word2">Let' + 
                              '<span class="superscript">s</span> Grow Your</span>' + 
                              '<span class="word2">Business</span>'
                },
                {
                    sentance: '<span class="word">Focus On Your</span>' + 
                              '<span class="word">Customer<span class="superscript">s</span>' + 
                              '</span>'
                }
            ],
            keyFeatures: [
                {
                    icon: 'code',
                    color: 'bg-eb6b3d',
                    title: 'Zero Configuration',
                    description: 'It\'s quick & easy to setup you\'ll be up and running in minutes and our experts are there to help if you need them'
                },
                {
                    icon: 'server',
                    color: 'bg-f78acb',
                    title: 'Simple Pricing',
                    description: 'We\'ve optimised our pricing so you only pay for what you need. You\'re free to change your plan whenever you need.'
                },
                {
                    icon: 'users',
                    color: 'bg-c679e3',
                    title: 'Smart Client',
                    description: 'Work as a team on all your customer interactions from anywhere, on any device with our secure Smart Client.'
                },
                {
                    icon: 'server',
                    color: 'bg-eb6b3d',
                    title: 'Surveys & Feedback',
                    description: 'Get instant feedback from your customers so you can quickly understand how well your processes are working.'
                }
            ],
            featureStatements:[
                {
                    title: 'Our Mission',
                    header: 'Our mission at Iconic is to provide exceptional communication services that help you build lasting relationships with your customers.',
                    image: 'assets/img/our-mission-great-customer-experience.jpg',
                    displayItems: 'column',
                    columnSize: '12',
                    items: [
                        {
                            icon: 'code',
                            title:'Build a reputable brand'
                        },
                        {
                            icon: 'smartphone',
                            title:'Get insight into customer behaviour'
                        },
                        {
                            icon: 'code',
                            title:'Help boost sales'
                        },
                        {
                            icon: 'code',
                            title:'Work closely with your team'
                        },
                        {
                            icon: 'code',
                            title:'Increase customer loyalty'
                        }
                    ],
                    footer: 'Our focus on customer experience is at the core of everything we do, and we strive to exceed expectations by giving you the capability to implement personalised and effective communication strategies that drive customer satisfaction and loyalty.'
                },
                // {
                //     title: 'Hassle Free Cloud Service',
                //     header: 'There\'s nothing to install upgrade or manage, simply sign-up and start creating experiences your customers will love.',
                //     image: 'assets/img/features-details-image/Cloud-platform.png',
                //     displayItems: 'row',
                //     items: [
                //         {
                //             icon: 'code',
                //             title:'Live Chat'
                //         },
                //         {
                //             icon: 'smartphone',
                //             title:'Secure Sign-in'
                //         },
                //         {
                //             icon: 'code',
                //             title:'Email Management'
                //         },
                //         {
                //             icon: 'code',
                //             title:'Real-time Dashboards'
                //         },
                //         {
                //             icon: 'code',
                //             title:'CRM Integration'
                //         },
                //         {
                //             icon: 'code',
                //             title:'Full Customer History'
                //         }
                //     ],
                //     footer: ''
                // }
            ],
            products: {
                title: 'Pick the perfect plan for you',
                header: 'Get the best of iconic cx™ with simple, transparent pricing and no surprise fees. Grow your business on our platform and omly pay for what you use.',
                actionName: 'Select Plan',
                productList: [
                    {
                        displayName:'CX Fusion Starter (Web chat)',
                        name: 'cxfusionstarter',
                        price: '25.00',
                        term: '/Month',
                        currency: '£',
                        features: [
                            {
                                title: '1 User',
                            },
                            {
                                title: 'Web Chat Routing',
                            },
                            {
                                title: '1 Chat Queue',
                            },
                            {
                                title: 'Customer Feedback',
                            },
                            {
                                title: 'Customer Analytics',
                            }
                        ]
                    },
                    {
                        displayName:'CX Fusion Digital (Digital)',
                        name: 'cxfusiondigital',
                        price: '39.00',
                        term: '/Month',
                        currency: '£',
                        className: 'featured-item',
                        features: [
                            {
                                title: '3 Users',
                            },
                            {
                                title: '5 Mailboxes',
                            },
                            {
                                title: '3 Live Chat Queues',
                            },
                            {
                                title: 'Dynamic Queue Management',
                            },
                            {
                                title: 'Skills Based Routing',
                            },
                            {
                                title: 'Analytics Dashboard',
                            },
                            {
                                title: 'Chat Transcriptions'
                            }
                        ]
                    },
                    {
                        displayName:'CX Fusion Voice (Digital + Web Calling)',
                        name: 'cxfusionvoice',
                        price: '69.00',
                        currency: '£',
                        term: '/Month',
                        features: [
                            {
                                title: '5 Users',
                                description: ''
                            },
                            {
                                title: '10 Mailboxes',
                                description: ''
                            },
                            {
                                title: '10 Chat Queues',
                                description: ''
                            },
                            {
                                title: 'Web Calling',
                                description: 'Let your customers call you directly from your website'
                            },
                            {
                                title: 'Voice IVR',
                                description: ''
                            },
                            {
                                title: 'Voicemail',
                                description: ''
                            },
                            {
                                title: 'Dynamic Queue Management',
                                description: ''
                            },
                            {
                                title: 'Skills Based Routing',
                                description: ''
                            },
                            {
                                title: 'Analytics Dashboard',
                                description: ''
                            }
                        ]
                    }
                ]
            },

            additionalFeatures: {
                title: 'Enhance You Plan',
                header: 'Get more out of the iconic cx™ platform with our state of the are AI features.',
                featureList: [
                    {
                        icon: 'settings',
                        title: 'Conversational Chatbots',
                        description: 'Never miss a sale - be available to your customers 24/7. Make it easy for them to get the information they need, when they need it.'
                    },
                    {
                        icon: 'mail',
                        title: 'Outbound Campaigns',
                        description: 'Generate more sales - send personalised, timely and relevant notifications to your customers on your their preferred channels.'
                    },
                    {
                        icon: 'grid',
                        color: 'bg-c679e3',
                        title: 'Social Media Manager',
                        description: 'Build your reputation - see what your customers are saying about your business and provide feedback with ease.'
                    },
                    {
                        icon: 'info',
                        color: 'bg-c679e3',
                        title: 'Qualitative Reporting',
                        description: 'Create positive vibes - instantly see how your customers feel about each interaction they have with you and understand why.'
                    }
                ]
            },
            requestInfo : {
                image: 'assets/img/contact-iconic.jpg',
                title: 'Request More Info',
                header: 'Get expert guidance on how to deliver great experiences — the smarter way',
                formFields: {
                    fullName: {
                        label: 'Full Name',
                        validationMesage: 'Full name is required'
                    },
                    email: {
                        label: 'Business Email',
                        validationMesage: 'Email address is required'
                    },
                    companyName: {
                        label: 'Company Name',
                        validationMesage: 'Company name is required'
                    },
                    phone: {
                        label: 'Phone Number',
                        validationMesage: ''
                    },
                    request : {
                        label: 'request',
                        validationMesage: 'Please select a topic',
                        topics: [
                            {
                                title: 'I\'m interested in...',
                                value: '',
                                selected: 'selected'
                            },
                            {
                                title: 'AI Powered Chat',
                                value: 'AI_Chat',
                            },
                            {
                                title: 'Speach & Conversational IVR',
                                value: 'IVR',
                            },
                            {
                                title: 'Digital Communications',
                                value: 'Digital_Comms',
                            },
                            {
                                title: 'Reporting & Analytics',
                                value: 'Reporting',
                            },
                            {
                                title: 'Social Media Integration',
                                value: 'Social_Media',
                            },
                            {
                                title: 'Voice Services',
                                value: 'Voice',
                            }
                            
                        ]
                    },            
                    confirmation: {
                        label: 'I agree to receive communications from iconic cx™',
                        validationMesage: 'Please check the box to agree to recieve information from the iconic team regarding your request.'
                    },
                    action: {
                        label : 'Get In Touch'
                    }
                }
            },
            features:[
                {
                    name:'minimun-users',
                    displayName:'Minimum number of users',
                    description: 'Number of users that must be registered for this service',
                    products: [
                        {
                            name:'essential',
                            value:1
                        },
                        {
                            name:'foundation',
                            value:3
                        },
                        {
                            name:'enablement',
                            value:5
                        }
                    ]
                },
                {
                    name: 'max-teams',
                    displayName: 'Maxumum number of teams',
                    description: 'Maximum nuber of teams that can be created for an oranisation',
                    products: [
                        {
                            name:'essential',
                            value:1,
                        },
                        {
                            name:'foundation',
                            value:5,
                        },
                        {
                            name:'enablement',
                            value:'unlimited',
                        }
                    ]
                },
                {
                    name:'skills-based-routing',
                    displayName:'Skills based routing',
                    description: 'Routing emails, chats and phone calls to operators based on routing attributes',
                    products: [
                        {
                            name:'essential',
                            icon: 'lnr-cross-circle',
                        },
                        {
                            name:'foundation',
                            icon: 'lnr-checkmark-circle',
                        },
                        {
                            name:'enablement',
                            icon: 'lnr-checkmark-circle',
                        }
                    ]
                },
                {
                    name: 'user-presence',
                    displayName: 'User presence',
                    description: 'See the status of your team members in real-time',
                    products: [
                        {
                            name:'essential',
                            value:'Included',
                        },
                        {
                            name:'foundation',
                            value:'Included',
                        },
                        {
                            name:'enablement',
                            value:'Included',
                        }
                    ]
                },
                {
                    name: 'chat-queues',
                    displayName: 'Number of chat queues',
                    description: 'Number of chat service queues which can be created',
                    products: [
                        {
                            name:'essential',
                            value:'1'
                        },
                        {
                            name:'foundation',
                            value:'3'
                        },
                        {
                            name:'enablement',
                            value:'Unlimited'
                        }
                    ]
                },
                {
                    name: 'max-queued-chats',
                    displayName: 'Total number of queued chat requests',
                    description: 'Number of customers who can wait in a queue before the chat queue is closed',
                    products: [
                        {
                            name:'essential',
                            value:'3'
                        },
                        {
                            name:'foundation',
                            value:'Unlimited'
                        },
                        {
                            name:'enablement',
                            value:'Unlimited'
                        }
                    ]   
                },
                {
                    name: 'chat-history-duration',
                    displayName: 'Chat transcript history duration',
                    description: 'Amount of time chat transcripts are avaiable online',
                    products: [
                        {
                            name:'essential',
                            value:'30 Days'
                        },
                        {
                            name:'foundation',
                            value:'3 Months'
                        },
                        {
                            name:'enablement',
                            value:'12 Months'
                        }
                    ]
                },
                {
                    name: 'chat-transcript-sending',
                    displayName: 'Send chat transcripts',
                    description: 'Automatically send customers a copy of their chat transcript',
                    products: [
                        {
                            name:'essential',
                            icon: 'lnr-cross-circle',
                            value:''
                        },
                        {
                            name:'foundation',
                            icon: 'lnr-checkmark-circle',
                            value:''
                        },
                        {
                            name:'enablement',
                            icon: 'lnr-checkmark-circle',
                            value:''
                        }
                    ]
                },
                {
                    name: 'chat-feedback',
                    displayName: 'Chat feedback',
                    description: 'Allow customers to rate and provide real-time feedback on their chat session',
                    products: [
                        {
                            name:'essential',
                            icon: 'lnr-cross-circle',
                            value:''
                        },
                        {
                            name:'foundation',
                            icon: 'lnr-checkmark-circle',
                            value:''
                        },
                        {
                            name:'enablement',
                            icon: 'lnr-checkmark-circle',
                            value:''
                        }
                    ]
                },
                {
                    name: 'chat-SSL-encryption ',
                    displayName: 'Encrypted chat communications and storage ',
                    description: 'All chat communication is encrypted and stored on encrypted storatge',
                    products: [
                        {
                            name:'essential',
                            icon: 'lnr-checkmark-circle',
                            value:''
                        },
                        {
                            name:'foundation',
                            icon: 'lnr-checkmark-circle',
                            value:''
                        },
                        {
                            name:'enablement',
                            icon: 'lnr-checkmark-circle',
                            value:''
                        }
                    ]
                },
                {
                    group:'email',
                    name: 'email-features ',
                    displayName: 'Email',
                    description: '',
                    products: [
                        {
                            name:'essential',
                            icon: '',
                            value:''
                        },
                        {
                            name:'foundation',
                            icon: '',
                            value:''
                        },
                        {
                            name:'enablement',
                            icon: '',
                            value:''
                        }
                    ]
                },
                {
                    group:'email',
                    name: 'email-mailboxes ',
                    displayName: 'Number of email mailboxes you can access',
                    description: 'Total number of email accounts you can monitor for customer emails',
                    products: [
                        {
                            name:'essential',
                            icon: '',
                            value:'1'
                        },
                        {
                            name:'foundation',
                            icon: '',
                            value:'3'
                        },
                        {
                            name:'enablement',
                            icon: '',
                            value:'unlimited'
                        }
                    ]
                },
                {
                    group:'email',
                    name: 'email-mailboxes',
                    displayName: 'Number of email mailboxes you can access',
                    description: 'Total number of email accounts you can monitor for customer emails',
                    products: [
                        {
                            name:'essential',
                            icon: '',
                            value:'1'
                        },
                        {
                            name:'foundation',
                            icon: '',
                            value:'3'
                        },
                        {
                            name:'enablement',
                            icon: '',
                            value:'unlimited'
                        }
                    ]
                },
                {
                    group:'email',
                    name: 'email-storage',
                    displayName: 'Email message storage',
                    description: 'Store full email content in encrypted storage for customer history, trend analysis and reporting',
                    products: [
                        {
                            name:'essential',
                            icon: '',
                            value:'30 Days'
                        },
                        {
                            name:'foundation',
                            icon: '',
                            value:'3 Months'
                        },
                        {
                            name:'enablement',
                            icon: '',
                            value:'12 Months'
                        }
                    ]
                },
                {
                    group:'voice',
                    name: 'voice',
                    displayName: 'Voice',
                    description: '',
                    products: [
                      
                    ]
                },
                {
                    group:'voice',
                    name: 'voice-softphone',
                    displayName: 'Native Web softphone',
                    description: 'Take calls using your web browser on any device',
                    products: [
                        {
                            name:'essential',
                            value: '-'
                        },
                        {
                            name:'foundation',
                            value: '-'
                        },
                        {
                            name:'enablement',
                            icon: 'lnr-checkmark-circle'
                        }
                    ]
                },
                {
                    group:'voice',
                    name: 'voice-cti',
                    displayName: 'Computer telephony integration (CTI)',
                    description: 'Receptionists and operators applications to perform click to dial, screen pop and streamline transfer of calls',
                    products: [
                        {
                            name:'essential',
                            value: '-'
                        },
                        {
                            name:'foundation',
                            value: '-'
                        },
                        {
                            name:'enablement',
                            icon: 'lnr-cross-circle'
                        }
                    ]
                },
                {
                    group:'voice',
                    name: 'voice-operator-call-recording',
                    displayName: 'Call recording for operator calls',
                    description: 'See the full picture and understand how your operators are performing',
                    products: [
                        {
                            name:'essential',
                            value: '-'
                        },
                        {
                            name:'foundation',
                            value: '-'
                        },
                        {
                            name:'enablement',
                            icon: 'lnr-cog'
                        }
                    ]
                },
                {
                    group:'voice',
                    name: 'voice-operator-call-recording',
                    displayName: 'Call recording for operator calls',
                    description: 'See the full picture and understand how your operators are performing',
                    products: [
                        {
                            name:'essential',
                            value: '-'
                        },
                        {
                            name:'foundation',
                            value: '-'
                        },
                        {
                            name:'enablement',
                            icon: 'lnr-cog'
                        }
                    ]
                },
                {
                    group:'voice',
                    name: 'voice-conference',
                    displayName: 'Conference calling',
                    description: 'Host secue conference calls with customers and partners',
                    products: [
                        {
                            name:'essential',
                            value: '-'
                        },
                        {
                            name:'foundation',
                            value: '-'
                        },
                        {
                            name:'enablement',
                            icon: 'lnr-cog'
                        }
                    ]
                },
                {
                    group:'voice',
                    name: 'voice-call-steering',
                    displayName: 'Professional IVR',
                    description: 'Classify callers according to their needs and automatically route them to the most relevant operator',
                    products: [
                        {
                            name:'essential',
                            value: '-'
                        },
                        {
                            name:'foundation',
                            value: '-'
                        },
                        {
                            name:'enablement',
                            icon: 'lnr-cog'
                        }
                    ]
                },
                {
                    group:'voice',
                    name: 'voice-call-queues',
                    displayName: 'Total call queues',
                    description: 'Customize the call queuing experience for your customers with personalized messages and waiting music',
                    products: [
                        {
                            name:'essential',
                            value: '-'
                        },
                        {
                            name:'foundation',
                            value: '-'
                        },
                        {
                            name:'enablement',
                            value: '3'
                        }
                    ]
                },
                {
                    group:'voice',
                    name: 'voice-call-classification',
                    displayName: 'Call tagging',
                    description: 'Allow operators to classify and tag calls to improve your analytics',
                    products: [
                        {
                            name:'essential',
                            value: '-'
                        },
                        {
                            name:'foundation',
                            value: '-'
                        },
                        {
                            name:'enablement',
                            value: '3'
                        }
                    ]
                },
                {
                    group:'voice',
                    name: 'voice-voicemail',
                    displayName: 'Voicemail',
                    description: 'Allow customers to leave you a message on indivudal or shared voicemail boxes',
                    products: [
                        {
                            name:'essential',
                            value: '-'
                        },
                        {
                            name:'foundation',
                            value: '-'
                        },
                        {
                            name:'enablement',
                            icon: 'lnr-checkmark-circle'
                        }
                    ]
                },
                {
                    name: 'support',
                    displayName: 'Support',
                    description: 'Get a helping hand whenever you need it',
                    products: [
                        {
                            name:'essential',
                            value:'Email Support',
                        },
                        {
                            name:'foundation',
                            value:'Email Support',
                        },
                        {
                            name:'enablement',
                            value:'Telephony/Email Support',
                        }
                    ]
                }
            ]
        },
        {
            name:'mediumenterprise',
            language: 'en-gb',
            backgroundImage: 'assets/img/medium-business-happy-user.jpg',
            heroStatements : [
                {
                    sentance: '<span class="word2">Let' + 
                              '<span class="superscript">s</span> Grow Your</span>' + 
                              '<span class="word2">Business</span>'
                },
                {
                    sentance: '<span class="word">Focus On Your</span>' + 
                              '<span class="word">Customer<span class="superscript">s</span>' + 
                              '</span>'
                }
            ],
            keyFeatures: [
                {
                    icon: 'code',
                    color: 'bg-f78acb',
                    title: 'Customer Journey',
                    description: 'Create fluid frictionless customer journeys across all your customer touchpoints.'
                },
                {
                    icon: 'server',
                    color: 'bg-eb6b3d',
                    title: 'Seamless Self-Service',
                    description: 'Automate complex transactions with ease, using our state of the art AI Chat and Voice Bots'
                },
                {
                    icon: 'users',
                    color: 'bg-c679e3',
                    title: 'Omni-Channel Routing',
                    description: 'Work as a team on all your customer interactions from anywhere, on any device with our secure Smart Client.'
                },
                {
                    icon: 'server',
                    color: 'bg-eb6b3d',
                    title: 'Empower Your Agents',
                    description: '.'
                }
            ],
            featureStatements:[
                {
                    title: 'A platform that grows with you',
                    header: 'Grow your business faster with all the tools and expertise you need to keep delighting your customers.',
                    image: 'assets/img/features-details-image/workforce-optimisation.png',
                    displayItems: 'row',
                    items: [
                        {
                            icon: 'code',
                            title:'Live Chat'
                        },
                        {
                            icon: 'smartphone',
                            title:'Secure Sign-in'
                        },
                        {
                            icon: 'code',
                            title:'Email Management'
                        },
                        {
                            icon: 'code',
                            title:'Real-time Dashboards'
                        },
                        {
                            icon: 'code',
                            title:'CRM Integration'
                        },
                        {
                            icon: 'code',
                            title:'Full Customer History'
                        }
                    ],
                    footer: ''
                },
                {
                    title: 'We\'re here to help you succeed',
                    header: 'Every interaction matters. We help you create positive experiences that inspire your customers to engage, advocate, and promote your brand.',
                    image: 'assets/img/our-commitment.jpg',
                    displayItems: 'column',
                    columnSize: '12',
                    items: [
                        {
                            icon: 'code',
                            title:'Set your contact center apart'
                        },
                        {
                            icon: 'smartphone',
                            title:'Easy integrations and limitless Innovation'
                        },
                        {
                            icon: 'code',
                            title:'Get smarter with integrated AI'
                        },
                        {
                            icon: 'code',
                            title:'Self service that works 24/7'
                        },
                        {
                            icon: 'code',
                            title:'Happier customers, across every channel'
                        }
                    ],
                    footer: 'We are dedicated to delivering innovative solutions that create meaningful and long lasting connections between our clients and their customers.'
                }
            ],
            products: {
                title: 'Pick the perfect plan for you',
                header: 'Get the best of iconic cx™ with simple, transparent pricing and no surprise fees. Grow your business on our platform and omly pay for what you use.',
                actionName: 'Select Plan',
                pricingStatement: 'Prices listed are per named user, billed monthly. Usage-based pricing may apply.',
                productList: [
                    {
                        displayName:'Essential (Digital Only)',
                        name: 'essential',
                        price: '0.00',
                        currency: '£',
                        term: '/Mon',
                        features: [
                            {
                                title: '3 Users',
                            },
                            {
                                title: '2 Mailboxes',
                            },
                            {
                                title: '1 Live Chat',
                            },
                            {
                                title: 'Analytics Dashboard',
                            },
                            {
                                title: 'Smart Client',
                            }
                        ]
                    },
                    {
                        displayName:'Foundation (Digital Only)',
                        name: 'foundation',
                        price: '39.00',
                        term: '/Mon',
                        currency: '£',
                        features: [
                            {
                                title: 'Unlimited User',
                            },
                            {
                                title: 'Unlimited Mailboxes',
                            },
                            {
                                title: 'Unlimited Live Chat',
                            },
                            {
                                title: 'Dynamic Queue Management',
                            },
                            {
                                title: 'Skills Based Routing',
                            },
                            {
                                title: 'Analytics Dashboard',
                            },
                            {
                                title: 'Smart Client',
                            }
                        ]
                    },
                    {
                        displayName:'Enablement (Digital + Voice)',
                        name: 'enablement',
                        price: '69.00',
                        term: '/Mon',
                        currency: '£',
                        className: 'featured-item',
                        features: [
                            {
                                title: 'Unlimited User',
                                description: ''
                            },
                            {
                                title: 'Unlimited Mailboxes',
                                description: ''
                            },
                            {
                                title: 'Unlimited Live Chat',
                                description: ''
                            },
                            {
                                title: 'Dynamic Queue Management',
                                description: ''
                            },
                            {
                                title: 'Skills Based Routing',
                                description: ''
                            },
                            {
                                title: 'Analytics Dashboard',
                                description: ''
                            },
                            {
                                title: 'Smart Client',
                                description: ''
                            },
                            {
                                title: 'Single Sign-On',
                                description: ''
                            }
                        ]
                    }
                ]
            },

            additionalFeatures: {
                title: 'Enhance You Plan',
                header: 'Get more out of the iconic cx™ platform with our state of the are AI features.',
                featureList: [
                    {
                        icon: 'settings',
                        title: 'Conversational Chatbots',
                        description: 'Never miss a sale - be available to your customers 24/7. Make it easy for them to get the information they need, when they need it.'
                    },
                    {
                        icon: 'mail',
                        title: 'Outbound Campaigns',
                        description: 'Generate more sales - send personalised, timely and relevant notifications to your customers on your their preferred channels.'
                    },
                    {
                        icon: 'grid',
                        color: 'bg-c679e3',
                        title: 'Social Media Manager',
                        description: 'Build your reputation - see what your customers are saying about your business and provide feedback with ease.'
                    },
                    {
                        icon: 'info',
                        color: 'bg-c679e3',
                        title: 'Qualitative Reporting',
                        description: 'Create positive vibes - instantly see how your customers feel about each interaction they have with you and understand why.'
                    }
                ]
            },
            requestInfo : {
                image: 'assets/img/contact-iconic.jpg',
                title: 'Request More Info',
                header: 'Get expert guidance on how to deliver great experiences — the smarter way',
                formFields: {
                    fullName: {
                        label: 'Full Name',
                        validationMesage: 'Full name is required'
                    },
                    email: {
                        label: 'Business Email',
                        validationMesage: 'Email address is required'
                    },
                    companyName: {
                        label: 'Company Name',
                        validationMesage: 'Company name is required'
                    },
                    phone: {
                        label: 'Phone Number',
                        validationMesage: ''
                    },
                    request : {
                        label: 'request',
                        validationMesage: 'Please select a topic',
                        topics: [
                            {
                                title: 'I\'m interested in...',
                                value: '',
                                selected: 'selected'
                            },
                            {
                                title: 'AI Powered Chat',
                                value: 'AI_Chat',
                            },
                            {
                                title: 'Speach & Conversational IVR',
                                value: 'IVR',
                            },
                            {
                                title: 'Digital Communications',
                                value: 'Digital_Comms',
                            },
                            {
                                title: 'Reporting & Analytics',
                                value: 'Reporting',
                            },
                            {
                                title: 'Social Media Integration',
                                value: 'Social_Media',
                            },
                            {
                                title: 'Voice Services',
                                value: 'Voice',
                            }
                            
                        ]
                    },            
                    confirmation: {
                        label: 'I agree to receive communications from iconic cx™',
                        validationMesage: 'Please check the box to agree to recieve information from the iconic team regarding your request.'
                    },
                    action: {
                        label : 'Get In Touch'
                    }
                }
            }

        },
        {
            name:'largeorganisation',
            language: 'en-gb',
            backgroundImage: 'assets/img/large-organisation.jpg',
            heroStatements : [
                {
                    sentance: '<span class="word2">Let' + 
                              '<span class="superscript">s</span> Grow Your</span>' + 
                              '<span class="word2">Business</span>'
                },
                {
                    sentance: '<span class="word">Focus On Your</span>' + 
                              '<span class="word">Customer<span class="superscript">s</span>' + 
                              '</span>'
                }
            ],
            keyFeatures: [
                {
                    icon: 'code',
                    color: 'bg-f78acb',
                    title: 'Quick & Easy',
                    description: 'Set up a cloud contact center in just a few clicks and onboard agents to support your customers across all your channels right away.'
                },
                {
                    icon: 'server',
                    color: 'bg-eb6b3d',
                    title: 'Omni-Channel CX',
                    description: 'Improve agent productivity and customer experience across voice and digital channels with the all-in-one, AI- and ML-powered contact center.'
                },
                {
                    icon: 'users',
                    color: 'bg-c679e3',
                    title: 'Scale With Ease',
                    description: 'Easily scale up or down to meet customer demand, with the flexibility to onboard tens of thousands of agents working from anywhere.'
                },
                {
                    icon: 'server',
                    color: 'bg-eb6b3d',
                    title: 'Save up to 80%',
                    description: 'Pay for only what you need and use. With no minimum fees, long-term commitments, or upfront license charges you\'re free to innovate and grow.'
                }
            ],
            featureStatements:[
                {
                    title: 'A platform that grows with you',
                    header: 'Deploy seamless customizations and pre-built integrations from our partners ecosystem — all through a secure and reliable cloud platform.',
                    image: 'assets/img/features-details-image/workforce-optimisation.png',
                    displayItems: 'row',
                    items: [
                        {
                            icon: 'code',
                            title:'AI Powered Chat Bots'
                        },
                        {
                            icon: 'smartphone',
                            title:'SAML Sign-in Support'
                        },
                        {
                            icon: 'code',
                            title:'Conversational IVR'
                        },
                        {
                            icon: 'code',
                            title:'Voice as a Service'
                        },
                        {
                            icon: 'code',
                            title:'Extensive Secure API'
                        },
                        {
                            icon: 'code',
                            title:'Single Pane of Glass'
                        }
                    ],
                    footer: ''
                },
                {
                    title: 'The foundation for your innovation',
                    header: 'We are committed to building strong partnerships with our clients and providing world-class communication services that help them achieve their goals and deliver exceptional customer experiences.',
                    image: 'assets/img/our-commitment.jpg',
                    displayItems: 'column',
                    columnSize: '12',
                    items: [
                        {
                            icon: 'code',
                            title:'Make smarter decissions with data driven insights'
                        },
                        {
                            icon: 'smartphone',
                            title:'Easy integrations and limitless Innovation'
                        },
                        {
                            icon: 'code',
                            title:'Seamless AI integrations across all your channels'
                        },
                        {
                            icon: 'code',
                            title:'Self service that works 24/7'
                        },
                        {
                            icon: 'code',
                            title:'Reduced customer churn'
                        }
                    ],
                    footer: 'We\'re here to help you stay ahead of your customer expectations and outpace your competition; while rapidly delivering improvements that retain existing customers and attract new ones.'
                }
            ],
            products: {
                title: 'Pick the perfect plan for you',
                header: 'Get the best of iconic cx™ with simple, transparent pricing and no surprise fees. Grow your business on our platform and omly pay for what you use.',
                actionName: 'Select Plan',
                productList: [
                    {
                        displayName:'Essential (Digital Only)',
                        name: 'essential',
                        price: 'Talk to Sales',
                        currency: '',
                        term: '',
                        features: [
                            {
                                title: '3 Users',
                            },
                            {
                                title: '2 Mailboxes',
                            },
                            {
                                title: '1 Live Chat',
                            },
                            {
                                title: 'Analytics Dashboard',
                            },
                            {
                                title: 'Smart Client',
                            }
                        ]
                    },
                    {
                        displayName:'Foundation (Digital Only)',
                        name: 'foundation',
                        price: 'Talk to Sales',
                        term: '',
                        currency: '',
                        features: [
                            {
                                title: 'Unlimited User',
                            },
                            {
                                title: 'Unlimited Mailboxes',
                            },
                            {
                                title: 'Unlimited Live Chat',
                            },
                            {
                                title: 'Dynamic Queue Management',
                            },
                            {
                                title: 'Skills Based Routing',
                            },
                            {
                                title: 'Analytics Dashboard',
                            },
                            {
                                title: 'Smart Client',
                            }
                        ]
                    }
                ]
            },

            additionalFeatures: {
                title: 'Enhance You Plan',
                header: 'Get more out of the iconic cx™ platform with our state of the are AI features.',
                featureList: [
                    {
                        icon: 'settings',
                        title: 'Conversational Chatbots',
                        description: 'Never miss a sale - be available to your customers 24/7. Make it easy for them to get the information they need, when they need it.'
                    },
                    {
                        icon: 'mail',
                        title: 'Outbound Campaigns',
                        description: 'Generate more sales - send personalised, timely and relevant notifications to your customers on your their preferred channels.'
                    },
                    {
                        icon: 'grid',
                        color: 'bg-c679e3',
                        title: 'Social Media Manager',
                        description: 'Build your reputation - see what your customers are saying about your business and provide feedback with ease.'
                    },
                    {
                        icon: 'info',
                        color: 'bg-c679e3',
                        title: 'Qualitative Reporting',
                        description: 'Create positive vibes - instantly see how your customers feel about each interaction they have with you and understand why.'
                    }
                ]
            },
            requestInfo : {
                image: 'assets/img/contact-iconic.jpg',
                title: 'Request More Info',
                header: 'Get expert guidance on how to deliver great experiences — the smarter way',
                formFields: {
                    fullName: {
                        label: 'Full Name',
                        validationMesage: 'Full name is required'
                    },
                    email: {
                        label: 'Business Email',
                        validationMesage: 'Email address is required'
                    },
                    companyName: {
                        label: 'Company Name',
                        validationMesage: 'Company name is required'
                    },
                    phone: {
                        label: 'Phone Number',
                        validationMesage: ''
                    },
                    request : {
                        label: 'request',
                        validationMesage: 'Please select a topic',
                        topics: [
                            {
                                title: 'I\'m interested in...',
                                value: '',
                                selected: 'selected'
                            },
                            {
                                title: 'AI Powered Chat',
                                value: 'AI_Chat',
                            },
                            {
                                title: 'Speach & Conversational IVR',
                                value: 'IVR',
                            },
                            {
                                title: 'Digital Communications',
                                value: 'Digital_Comms',
                            },
                            {
                                title: 'Reporting & Analytics',
                                value: 'Reporting',
                            },
                            {
                                title: 'Social Media Integration',
                                value: 'Social_Media',
                            },
                            {
                                title: 'Voice Services',
                                value: 'Voice',
                            }
                            
                        ]
                    },            
                    confirmation: {
                        label: 'I agree to receive communications from iconic cx™',
                        validationMesage: 'Please check the box to agree to recieve information from the iconic team regarding your request.'
                    },
                    action: {
                        label : 'Get In Touch'
                    }
                }
            }

        }
    ]
}