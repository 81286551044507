<!-- Banner -->
<section class="pt-7">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6 text-md-start text-center py-6">
                <h1 class="mb-4 fs-9 fw-bold">Let's Grow Your Business</h1>
                <p class="mb-6 lead text-secondary">
                    Transform the way you engage with your customers<br class="d-none d-xl-block" />
                    and turn every interaction into an opportunity to<br class="d-none d-xl-block" />
                    build lasting loyalty.</p>
                <div class="text-center text-md-start">
                    <a class="btn btn-warning me-3 btn-lg" routerLink="/contactus" role="button">Request a demo</a>
                    <a class="btn btn-link text-warning fw-medium" routerLink="/contactus" role="button">
                        <span class="fas fa-play me-2"></span>Contact Sales</a>
                </div>
            </div>
            <div class="col-md-6 text-end">
                <img class="pt-7 pt-md-0 img-fluid" src="assets/img/hero/hero-img.png" alt="" />
            </div>
        </div>
    </div>
</section>

<!-- ============================================-->
<!-- <section> begin ============================-->

    <div class="container">

        <div class="row">
            <ng-container *ngFor="let f of solution.keyFeatures;let i = index">
                <div class="col-lg-3 col-sm-6 mb-2"> <img class="mb-3 ms-n3" src="assets/img/category/icon{{i + 1}}.png"
                        width="75" alt={{f.title}} />
                    <h4 class="mb-3">{{f.title}}</h4>
                    <p class="mb-0 fw-medium text-secondary">{{f.description}}</p>
                </div>
            </ng-container>
        </div>
        <br />
    </div><!-- end of .container-->

    <br/>
    <br/>
<!-- Pricing -->
<div class="container pricing-area">
    <app-product-list [products]="solution.products"></app-product-list>
</div>



<div class="ServicesSection">
    <div *ngFor="let s of solution.featureStatements let index = index" class="services-area ptb-80">
        <div *ngIf=" index%2 === 0" class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 services-content">
                    <div class="section-title">
                        <h2>{{s.title}}</h2>
                        <div class="bar"></div>
                        <p>{{s.header}}</p>
                    </div>
                    <div class="{{s.displayItems}}">
                        <div class="col-lg-{{s.columnSize}} col-md-6 col-sm-6" *ngFor="let i of s.items">
                            <div class="box">
                                {{i.title}}
                            </div>
                        </div>
                        <p></p>
                        <p>{{s.footer}}</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 services-right-image single-right-image">
                    <img src="{{s.image}}" class="animate__animated animate__fadeInUp" alt="main-pic 0">
                </div>
            </div>
        </div>



        <div *ngIf=" index%2 === 1" class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 services-left-image single-left-image">

                    <img src="{{s.image}}" class="animate__animated animate__fadeInUp" alt="main-pic 1">
                </div>
                <div class="col-lg-6 col-md-6 services-content">
                    <div class="section-title">
                        <h2>{{s.title}}</h2>
                        <div class="bar"></div>
                        <p>{{s.header}}</p>
                    </div>
                    <div class="{{s.displayItems}}">
                        <div class="col-lg-{{s.columnSize}} col-md-6 col-sm-6" *ngFor="let i of s.items">
                            <div class="box">
                                {{i.title}}
                            </div>
                        </div>
                        <p></p>
                        <p>{{s.footer}}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<br />
<br />



<!-- Customer Feedback -->



<!-- Additional Features -->

<section class="pt-8">

    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xxl-5 text-center mx-auto">
          <h2>{{solution.additionalFeatures.title}}</h2>
          <p class="mb-4">{{solution.additionalFeatures.header}}</p>
        </div>
      </div>
      <div class="row align-items-center mt-5">
        <div class="col-md-5 col-lg-6 order-md-1 order-0"><img class="w-100" src="assets/img/gallery/join-us.png" alt="" /></div>
        <div class="col-md-7 col-lg-6 pe-lg-4 pe-xl-7">
          <div class="d-flex align-items-start" *ngFor="let f of solution.additionalFeatures.featureList">
            <img class="me-4" src="assets/img/icons/give-a-care.png" alt="" width="30" />
            <div class="flex-1">
              <h5>{{f.title}} </h5>
              <p class="text-muted mb-4">{{f.description}}</p>
            </div>
          </div>


          <br/>
          <div class="text-center"><a class="btn btn-warning" routerLink="/register/service/fustionstarter" role="button">Register Free</a></div>

        </div>
      </div>
    </div>
    <!-- end of .container-->

  </section>



<!-- Logos-->
<section class="pb-lg-0 pt-md-8 py-0">

    <div class="container">
        <div class="row align-items-center justify-content-xl-between py-5 border-klean">
            <div class="col-auto col-md-4 col-lg-auto my-3 text-xl-start">
                <img src="assets/img/gallery/NorthWestCommunityServiceTraining.jpeg" height="80" alt="..." />
            </div>
            <div class="col-auto col-md-4 col-lg-auto my-3 text-xl-start">
                <img src="assets/img/gallery/PeterFinn_icon.png" height="80" alt="..." />
                </div>
            <div class="col-auto col-md-4 col-lg-auto my-3 text-xl-start">
                <img src="assets/img/gallery/RobyGlazingLtd.png" height="80" alt="..." /></div>
            <div class="col-auto col-md-4 col-lg-auto my-3 text-xl-start">
                <img src="assets/img/gallery/hubspot.png" height="40" alt="..." /></div>
            <div class="col-auto col-md-4 col-lg-auto my-3 text-xl-start">
                <img src="assets/img/gallery/tunein.png" height="40" alt="..." /></div>
        </div>
    </div>
    <!-- end of .container-->

</section>
<!-- Contact -->
<div class="contact-area ptb-80">
    <div class="container">

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <img src="{{solution.requestInfo.image}}" alt="contact iconic.cx for more information">
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="ready-to-talk">
                    <div class="container">
                        <div class="section-title">
                            <h5>{{solution.requestInfo.title}}</h5>
                            
                            <p>{{solution.requestInfo.header}}</p>
                        </div>
                        <p>Our CX Experts are here to answer your questions and help you create experiences your customer will love</p>
                        <a routerLink="/contactus" class="btn btn-outline-secondary">Contact Us</a>
                        
                        <a class="btn btn-warning ms-3" routerLink="/register/service/fustionstarter">Try For Free</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


