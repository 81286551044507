<ng-container [ngSwitch]="layout$ | async">

    <app-application-external *ngSwitchCase="AppLayoutType.AppExternal">      
    </app-application-external>

    <app-homepage *ngSwitchCase="AppLayoutType.CorporateHome">  
    </app-homepage>

    <app-feature *ngSwitchCase="AppLayoutType.CorporateFeature">  
    </app-feature>

    <app-feature-detail *ngSwitchCase="AppLayoutType.CorporateFeatureDetail">  
    </app-feature-detail>

    <app-product-registration *ngSwitchCase="AppLayoutType.CorporateCheckout">  
    </app-product-registration>

    <app-homepage *ngSwitchDefault>
    </app-homepage>
  </ng-container>

