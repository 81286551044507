import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent {
  @Input() columnsToDisplay: string[] = [];
  @Input() dataSource:any[] = [];
  @Input() editUrl:string | undefined;
  @Input() itemId:string | undefined;

  constructor(private router:Router){

  }

  public displayEntity(row:any){
    if(this.editUrl && this.itemId != undefined){}
      this.router.navigateByUrl(this.editUrl + row[this.itemId!]);
  }
}
