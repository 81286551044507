import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LineOfBusinessService } from 'src/app/_services/line-of-business.service';
import { TermsAndConditionsService } from 'src/app/_services/terms-and-conditions.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit{

    language = 'en-GB';
    docError:string = '';
    selectedDocument:string = "";


    constructor(private tandCLOB:TermsAndConditionsService, private router: Router, private route: ActivatedRoute){
        
    }
    ngOnInit(): void {
        try{

            this.route.params.subscribe(param => {
                const policyDocument = param['policyDocument'];
                if(policyDocument)
                    this.displayDoc(policyDocument);
            });
        
        }catch(err){
            console.error('Error getting policy document');
        }
    }

    private getPolicyDocument(policyDocument:string | undefined){
        try{
            
            if(!policyDocument)
                policyDocument = '';
            
            policyDocument += '_' + this.language;
            
            this.tandCLOB.getData('policyDocument', policyDocument).subscribe({
                next: (doc)=> {
                    //display the doc
                },
                error(err) {
                    console.error("Unable to get policy document " + policyDocument, err);
                    //display message policy doc not found         
                },
            });
        }catch(err){
            console.error("Unable to get policy document " + policyDocument, err);
        }
    }

    displayDoc(doc:string) {
        this.selectedDocument = doc;
    }


}
