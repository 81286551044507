import { Injectable } from '@angular/core';
import { LineOfBusinessService } from './line-of-business.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { contactInfo } from '../_models/core';
import { Observable, map } from 'rxjs';
import { isValidEmail } from '../_helpers/utility-functions';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService extends LineOfBusinessService{

  constructor(protected httpClient:HttpClient) {
    super(httpClient);
   }

   createContactRequest(contactReq:contactInfo) : Observable<string> {
        try{
            if(!contactReq)
                throw new Error('Entity_Data_Required');

            if(!isValidEmail(contactReq.emailAddress))
                throw new Error('Invalid_Email_Address');

            return this.http.put<any>(this.apiURL + this.crmDataPath, JSON.stringify(contactReq,this.replacer) , { headers: new HttpHeaders().set('Content-Type', 'application/json'), observe: 'response' })
            .pipe(map((res:any) => {
                try {
                    console.log('Contact Request Response', res.body);
                    return 'Saved';
                }catch(err){
                    console.error('Error saving form data', err);
                    throw err;
                }
            }));
        }catch(err){
            console.error('Error creating contact request', err);
            throw err;
        }
    }
}