<div class='tabs'>

    <ng-container *ngFor="let price of productPrices; let i=index">
        <span class="{{'t' + (i + 1)}}" (click)=selectSubTab(i) [ngClass]="(selectedSubTab === (i  + 1)) ? 'showSubTab' : 'hideSubTab' ">{{'product-price.' + price.term.toString() + '_tabTitle' | translate}}</span>
        <div class='content'>
            <p>
                {{'product-price.' + price.term.toString() + '_description' | translate}}
                 <select id="currency" (change)="selectedCurrencyChanged($event, i)" class="form-select">
                    <option *ngFor="let unitPrice of price.unitPrices | keyvalue" [selected]="currency == unitPrice.key" value="{{unitPrice.key}}">
                        {{unitPrice.key}}
                    </option>
                </select>                   
            </p>
            <p>
                {{'product-price.priceToPay' | translate}} {{totalCost | currency : currency.toUpperCase()}} {{'product-price.' + price.term.toString() | translate}}
            </p>
        </div>
    </ng-container>

    <span class="t3" (click)=selectSubTab(2) [ngClass]="(selectedSubTab === 3) ? 'showSubTab' : 'hideSubTab' ">Get a Quote</span><!--{{'getQuote_tabTitle' | translate}}-->
    <div class='content'>
        <p>Speak to a member of our sales team to get a quote and find out how iconic.cx can help transform your customer experience.</p>
    </div>
<div class='slider'></div>

</div>