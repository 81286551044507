import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Route, Router, RoutesRecognized } from '@angular/router';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-tab0',
  templateUrl: './tab0.component.html',
  styleUrls: ['./tab0.component.scss']
})
export class Tab0Component implements OnInit{

  public tabs: Tab[] = [];
  public routes: Route[] = [];
  public currentHoverTabKey: string | null = null;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    // listen to routing change event to attach new tabs or activate a new one
    router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        this.checkAndAddRouteTab(val);
      }
    });
  }

  ngOnInit() {
    // get all routes to mock a navigation
    this.routes = this.router.config;
  }


  disposeTab(tab: Tab) {
    if (this.tabs.length > 1) {
      this.tabs = this.tabs.filter(item => item.key !== tab.key);

      if (tab.active) {
        // deactivate all tabs
        this.deactivateTabs();
        const path = this.tabs[this.tabs.length - 1].route.path
        if(path)
          this.router.navigateByUrl(path);
      }
    }
  }

  mouseOverTab(tab: Tab | null) {
    this.currentHoverTabKey = tab ? tab.key : null;
  }

  checkAndAddRouteTab(val: RoutesRecognized) {
    // get the component to activate by the route
    let r = val.state.root.children.map((r) => {while(r.firstChild) {
      console.debug('reviewing ',r);
      r = r.firstChild
    }
      return r})
    //route.state.root.firstChild?.firstChild?.component;

    let comp = r[0].component;

    // deactivate all tabs
    this.deactivateTabs();

    console.log('Component to display', comp);
    if(!comp)
      comp = MenuComponent;


    // check if the tab to be activated is already existing
    if (this.tabs.find(tab => tab.name == comp!["name"]) == null) {

      // if not, push it into the tab array
      this.tabs.push({
        name: comp["name"],
        component: comp,
        key: comp["name"],
        active: true,
        route: val.state.root.firstChild?.routeConfig!
      });

    } else {
      // if the tab exists, activate it
      const tabToActivate = this.tabs.find(tab => tab.name == comp!["name"]);
      if (tabToActivate) {
        tabToActivate.active = true;
      }
    }

    this.cd.markForCheck();
  }


  deactivateTabs() {
    this.tabs.forEach(tab => (tab.active = false));
  }

}

export interface Tab {
  name: string;
  component: any;
  active: boolean;
  route: Route;
  key: string;
}