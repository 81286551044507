import { Component } from '@angular/core';

@Component({
  selector: 'app-corporate-footer',
  templateUrl: './corporate-footer.component.html',
  styleUrls: ['./corporate-footer.component.scss']
})
export class CorporateFooterComponent {

}
