import { Component } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {

  public toggleClass(){
     // Toggle Collapse
    // $('.faq li .question').click(function () {
    //   $(this).find('.plus-minus-toggle').toggleClass('collapsed');
    //   $(this).parent().toggleClass('active');
    // });

  }
}


