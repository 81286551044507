<br />

<div class="container">
    <h1>
        Now check your email
    </h1>
    <br/>
    <div class="row align-items-top">
        <div class="col-lg-6 col-md-12">

            <div class="col-md-12 mb-5 mb-md-0">
                <div class="card card-span bg-primary h-100">
                  <div class="card-body p-4">
                    <div class="d-flex justify-content-between"><img class="mb-3" src="assets/img/icons/rocket.png" height="80" alt="..." /><img src="assets/img/icons/clouds-1.png" height="90" alt="..." /></div>
                    <h5 class="text-light">We just sent you an email</h5>
                    
                    <p class="text-light">
                        Check your mailbox for an email from hello@iconic.cx.</p>
                    
                    <p class="text-light">Click on the link or copy the 6 digit registration code and enter it here.
                    </p>
                    
                    <div class="text-end">
                       
                    </div>
                  </div>
                </div>
              </div>
              <br/>
           
              <div class="registrationHelp"> 
                <h5>Got a question?</h5>
                <p>Get in touch with one of our CX Specialists who will be able to help you become iconic</p>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 registration">
            <br/>
            <h5>Confirm Registration Code</h5>
            <br/>
            <div class="form-group" *ngIf="registratonCodeError !=''">
                <div class="alert alert-danger" role="alert">
                    <span>{{registratonCodeError | translate}}</span>
                </div>
            </div>

            <form id="verifyEmail" #verifyEmail="ngForm" (ngSubmit)="submit(verifyEmail)">
                <div class="form-group">
                    <label>Registration Code</label>
                    <input type="text" ngModel name="regCode" class="form-control" placeholder="Enter Registration Code"
                    [class.is-valid]="regCode.valid && regCode.touched" [ngClass]="(regCode.errors && regCode.touched) ? 'is-invalid' : ''" 

                        id="regCode" maxlength="6" minlength="6" required #regCode="ngModel">

                    <div class="invalid-feedback" *ngIf="regCode.invalid && regCode.touched">
                        <span *ngIf="regCode.errors?.['required']">Please enter your registration code.</span>
                        <span *ngIf="regCode.errors?.['minlength']">
                            Your registration code is 6 characters long.
                        </span>
                    </div>
                </div>
                <br/>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button type="submit" class="btn btn-primary float-right" [disabled]="isLoading">
                        <span [class.spinner-border]="isLoading"[class.spinner-border-sm]="isLoading" role="status" aria-hidden="true"></span>
                        Confirm Code
                      </button>
                </div> 
            </form>
        </div>
    </div>
</div>