import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss']
})
export class PhoneNumberInputComponent implements AfterViewInit{

    @ViewChild("phoneNumber",) telInput: ElementRef<HTMLInputElement> | undefined;

    ngAfterViewInit(): void {
        if(!this.telInput)
            return;



    }

    
}
