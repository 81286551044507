import { AfterViewInit, Component, OnDestroy } from '@angular/core';

// import Reveal from 'reveal.js';
// import Markdown from 'reveal.js/plugin/markdown/markdown';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})

export class HomepageComponent implements AfterViewInit, OnDestroy{

//   deck = new Reveal({
//     hash: true,
//     autoSlide: 8000,
//     loop: true,
//     embedded: true,
//     plugins: [ Markdown ]
//  });

  ngOnDestroy(): void {
    //this.deck.destroy();
  }

  ngAfterViewInit(): void {
    
//     this.deck = new Reveal({
//       hash: true,
//       autoSlide: 8000,
//       loop: true,
//       embedded: true,
//       plugins: [ Markdown ]
//    });

//    this.deck.initialize();

    // Reveal.initialize({
    //   hash: true,
    //   autoSlide: 5000,
    //   loop: true
    // });
  }

  
}
