import { Component, Input } from '@angular/core';
import { TaskType } from 'src/app/_models/core';

@Component({
  selector: 'app-task-icon',
  templateUrl: './task-icon.component.html',
  styleUrls: ['./task-icon.component.scss']
})
export class TaskIconComponent {
  @Input() text:string | undefined = '';
  @Input() taskType:TaskType | undefined= TaskType.Unknown;
}
