import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { contactInfo } from 'src/app/_models/core';
import { ContactUsService } from 'src/app/_services/contact-us.service';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {

    rightPanelActive: string = '';
    isLoading:boolean = false;
    formError:string = '';
    formSubmitted:string = '';

    constructor(private lob: ContactUsService, private route: ActivatedRoute){

    }
    
    toggleContainerClass() {
        if (this.rightPanelActive == '')
            this.rightPanelActive = 'right-panel-active'
        else
            this.rightPanelActive = '';
    }

    sendContact(form:NgForm){
        try{
            const firstName = form.value["firstName"];
            const lastName = form.value["lastName"];
            const companyName = form.value["companyName"];
            const phoneNumber = form.value["formattedPhoneNumber"];
            const emailAddress = form.value["emailAddress"];
            const jobTitle = form.value["jobTitle"];
            //const language = form.value["language"];
            //const country = form.value["country"];
            const interest = form.value["interest"];
            const customerQuery = form.value["customerQuery"];

            const agreeToServiceAgreement = form.value["agreeToServiceAgreement"];

            let contactInfo: contactInfo = {firstName, lastName, companyName, phoneNumber, emailAddress, jobTitle, agreeToServiceAgreement, interest, customerQuery};

            //console.log(await this.http.post(url,registration).toPromise());

            this.isLoading = true;
            this.lob.createContactRequest(contactInfo).subscribe({
                next: regResult => {
                    //console.log('Registration Result', regResult);
                    this.isLoading = false;
                    this.formError = "";
                    this.formSubmitted = "Thank you, one of our CX Experts will be in touch."
                },
                error: err => {
                    this.isLoading = false;
                    this.formError = "We were unable to process your request, please check the details and try again";
                }
            });
        
        } catch (err) {
            this.isLoading = false;
            console.error('Registration Error', err);
        }
    }
}
