import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
    isLoading:boolean = false
    @Input() displayLoading = false;
    
    show(){
      this.isLoading = false;
    }
  
    hide(){
      this.isLoading = false;
    }
  
    get canDisplay() : boolean {
      return (this.isLoading && this.displayLoading)
    }
}
