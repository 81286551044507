import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IFormData } from '../_models/core';

@Injectable({
  providedIn: 'root'
})
export class LineOfBusinessService {


  protected apiURL: string = environment.api.schema + '://' + environment.api.host + ':' + environment.api.port + '/';
  protected crmDataPath: string = 'product';//environment.api.paths.crmDataPath;

  constructor(protected http:HttpClient) { }
  getData(entityType:string, entityId:string | undefined = undefined, filter:string | undefined = undefined) : Observable<any> {  
    try{
      if(!entityType)
        throw new Error('Entity_Type_Required');

      let querystring:string = '?entityType=' + entityType;

      if(entityId)
        querystring += '&entityId=' + entityId;
      else if(filter){
        querystring += '&filter=' + filter;
      }
      return this.http.get<any>(this.apiURL + this.crmDataPath + querystring, { headers: new HttpHeaders().set('Content-Type', 'application/json'), observe: 'response' })

        .pipe(
          catchError(this.httpError)
        )
        .pipe(map((res) => {
          try {

            console.log('http response',res);
            let responseMessage:IFormData[] = [];

            res.body.forEach((record:any) => {
              const recString = JSON.stringify(record);

              //console.log('The record', record, recString);
              const data = JSON.parse(recString,this.reviver);
              //console.log('Record Data', data);
              responseMessage.push(data);
            });
            
            //console.log('http responseMessage',responseMessage);

            //const headers:HttpHeaders = res.headers;
            
            //console.log(responseMessage);

            return responseMessage;
            
          } catch (err) {
            console.error('Error getEntityDataById', err);
            throw(err);
          }

        })
      );
    }catch(err){
      console.error('Error Logging into iconic', err);
      throw err;
    }
  }

  protected httpError(error: any) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error(error));
  }

  protected replacer(key: string, value:any) {
    if(value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
      };
    } else {
      return value;
    }
  }

  //revive maps in messages
  protected reviver(key:any, value:any) {
  if(typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
      return new Map(value.value);
      }
  }
  return value;
  }
}
