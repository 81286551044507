import { Injectable } from '@angular/core';
import { LineOfBusinessService } from './line-of-business.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class TermsAndConditionsService extends LineOfBusinessService {

    protected override crmDataPath: string = 'policy';

    constructor(protected override http: HttpClient) {
        super(http)
    }
}
