import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, filter, map, mergeMap } from 'rxjs';
import { AppLayoutType } from './_models/UI';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tabs';

  layout$ = this.getLayoutType$()

  readonly AppLayoutType = AppLayoutType


  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    translate: TranslateService
    ){
        let lang = navigator.language;
        if(!lang)
            lang = 'en-GB';

      translate.setDefaultLang(lang);
      translate.use(lang);
    }
 
  private getLayoutType$(): Observable<AppLayoutType> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild
        }
        return route
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
      map(({ layout }) => layout),
    )
  }
}