<section class="py-0 py-xxl-6" id="help">
    <div class="bg-holder" style="background-image:url(assets/img/gallery/footer-bg.png);background-position:center;background-size:cover;">
    </div>
    <!--/.bg-holder-->

    <div class="container">
      <div class="row min-vh-75 min-vh-xl-50 pt-10">
        <div class="col-6 col-md-4 col-xl-3 mb-3">
          <h5 class="lh-lg fw-bold text-white">HOSTING</h5>
          <ul class="list-unstyled mb-md-4 mb-lg-0">
            <li class="lh-lg"><a class="text-200 text-decoration-none" href="#!">Our Platform</a></li>
            <li class="lh-lg"><a class="text-200 text-decoration-none" href="#!"></a></li>
            <li class="lh-lg"><a class="text-200 text-decoration-none" href="#!">Cloud Hosting</a></li>
            <li class="lh-lg"><a class="text-200 text-decoration-none" href="#!">Wordpress Hosting</a></li>
          </ul>
        </div>
        <div class="col-6 col-md-4 col-xl-3 mb-3">
          <h5 class="lh-lg fw-bold text-white">About US</h5>
          <ul class="list-unstyled mb-md-4 mb-lg-0">
            <li class="lh-lg"><a class="text-200 text-decoration-none" href="#!"></a></li>
            <li class="lh-lg"><a class="text-200 text-decoration-none" href="#!">Become A Reseller</a></li>
            <li class="lh-lg"><a class="text-200 text-decoration-none" href="#!">Work With Us</a></li>
            <li class="lh-lg"><a class="text-200 text-decoration-none" href="#!">Contact Us</a></li>
          </ul>
        </div>
        <div class="col-6 col-md-4 col-xl-3 mb-3">
          <h5 class="lh-lg fw-bold text-white">Legal Stuff</h5>
          <ul class="list-unstyled mb-md-4 mb-lg-0">
            <li class="lh-lg"><a class="text-200 text-decoration-none" routerLink="/terms-and-conditions/termsOfService">General Terms Of Service</a></li>
            <li class="lh-lg"><a class="text-200 text-decoration-none" routerLink="/terms-and-conditions/refundPolicy">Refund Policy</a></li>
            <li class="lh-lg"><a class="text-200 text-decoration-none" routerLink="/terms-and-conditions/cancellationPolicy">Cancellation Policy</a></li>
            <li class="lh-lg"><a class="text-200 text-decoration-none" routerLink="/terms-and-conditions/dataPrivacy">Consumer Data Privacy Policy</a></li>
          </ul>
        </div>
        <div class="col-xl-3 mb-3">
          <h5 class="lh-lg fw-bold text-white">Get Social</h5>
          <ul class="list-unstyled list-inline mb-6 mb-md-0">
            <li class="list-inline-item mr-2"><a class="text-decoration-none" routerLink="/terms-and-conditions"><img class="list-social-icon" src="assets/img/gallery/visa.png" width="50" alt="..." /></a></li>
            <li class="list-inline-item mr-2"><a class="text-decoration-none" routerLink="/register/service/fustionstarter"><img class="list-social-icon" src="assets/img/gallery/master-card.png" width="50" alt="..." /></a></li>
            <li class="list-inline-item mr-2"><a class="text-decoration-none" routerLink="/register/service/fustionstarter"><img class="list-social-icon" src="assets/img/gallery/union-pay.png" width="50" alt="..." /></a></li>
            <li class="list-inline-item"><a class="text-decoration-none" href="#!"><img class="list-social-icon" src="assets/img/gallery/express.png" width="50" alt="..." /></a></li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="row flex-center pb-3">
        <div class="col-md-6 order-0">
          <p class="text-200 text-center text-md-start">All rights Reserved &copy; Creative Communications Lab Ltd, 2024</p>
        </div>
        <div class="col-md-6 order-1">
          <p class="text-200 text-center text-md-end"> iconic.cx is made with&nbsp;
            <svg class="bi bi-suit-heart-fill" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#A8B7FF" viewBox="0 0 16 16">
              <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z"></path>
            </svg>&nbsp;by&nbsp;<a class="text-200 fw-bold" href="https://www.creativecommunicationslab.com/" target="_blank">Creative Communications Lab </a>
          </p>
        </div>
      </div>
    </div>
  </section>