<main class="main" id="top">


    <app-corporate-navbar></app-corporate-navbar>

    <section class="pt-7">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 text-md-start text-center py-6">
                    <h1 class="mb-4 fs-9 fw-bold">Truely Know Your Customers</h1>
                    <p class="mb-6 lead text-secondary">
                        Create positive interactions however<br class="d-none d-xl-block" />
                        ever and whenever your customers choose<br class="d-none d-xl-block" />
                        to interact with you.</p>
                    <div class="text-center text-md-start">
                        <a class="btn btn-primary me-3 btn-lg" routerLink="/contactus" role="button">Request a demo</a>
                        <a class="btn btn-outline-secondary fw-medium" routerLink="/contactus" role="button">
                            <span class="fas fa-play me-2"></span>Contact Sales</a>
                    </div>
                </div>
                <div class="col-md-6 text-end"><img class="pt-7 pt-md-0 img-fluid" src="assets/img/hero/hero-img.png"
                        alt="" /></div>
            </div>
        </div>
    </section>

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="pt-5 pt-md-9 mb-6" id="feature">

        <div class="bg-holder z-index--1 bottom-0 d-none d-lg-block"
            style="background-image:url(assets/img/category/shape.png);opacity:.5;">
        </div>
        <!--/.bg-holder-->

        <div class="container">
            <h1 class="fs-9 fw-bold mb-4 text-center">Elevate Your Customer Experience<br
                    class="d-none d-xl-block" />Today</h1>
            <div class="row">
                <div class="col-lg-3 col-sm-6 mb-2"> <img class="mb-3 ms-n3" src="assets/img/category/icon1.png"
                        width="75" alt="Intelligent AI Capabilities" />
                    <h4 class="mb-3">Intelligent AI<br />Capabilities</h4>
                    <p class="mb-0 fw-medium text-secondary">Leverage cutting-edge AI technologies for intelligent
                        routing, sentiment analysis, and predictive analytics.</p>
                </div>
                <div class="col-lg-3 col-sm-6 mb-2"> <img class="mb-3 ms-n3" src="assets/img/category/icon2.png"
                        width="75" alt="Omni-Channel Customer Engagements" />
                    <h4 class="mb-3">Omni-Channel Customer<br />Engagements</h4>
                    <p class="mb-0 fw-medium text-secondary">Seamlessly manage customer interactions across voice,
                        email, social media, chat and more from a single unified platform.</p>
                </div>
                <div class="col-lg-3 col-sm-6 mb-2"> <img class="mb-3 ms-n3" src="assets/img/category/icon3.png"
                        width="75" alt="Realtime Data Driven Insights" />
                    <h4 class="mb-3">Realtime Data Driven<br />Insights</h4>
                    <p class="mb-0 fw-medium text-secondary">Gain actionable insights and optimize operations and
                        customer experiences based on data-driven decision-making.</p>
                </div>
                <div class="col-lg-3 col-sm-6 mb-2"> <img class="mb-3 ms-n3" src="assets/img/category/icon4.png"
                        width="75" alt="Secure, Scalable and Flexible" />
                    <h4 class="mb-3">Secure, Scalable &<br /> Flexible</h4>
                    <p class="mb-0 fw-medium text-secondary">Scale your contact centre effortlessly and enjoy the
                        flexibility of a cloud-based solution which allows you to work from anywhere.</p>
                </div>
            </div>
            <br />
            <div class="text-center"><a class="btn btn-primary" routerLink="/register/service/fustionstarter" role="button">Register Free</a></div>
        </div><!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->



    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="pt-5" id="marketing">

        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-xxl-5 text-center mx-auto mb-5">
                    <h1>A Solution That's Right<br class="d-none d-xl-block"/>For You</h1>
                    <p class="mb-5">Join 100's of companies who save time, money and provide great service by using iconic.cx.</p>
                </div>
            </div>
           
            <div class="row">
                <div class="col-md-4 mb-4">
                    <div class="card"><img class="card-img-top" src="assets/img/marketing/marketing01.png" alt="" />
                        <div class="card-body ps-0">
                            <h4 class="fw-bold"><a class="fw-bold text-decoration-none me-1"
                                    routerLink="/solutions/smallbusiness">Got a small team of fewer than 10 people?</a></h4>
                                    <p>Build your business into a successful enterprise by providing personalised interactions which help biuld customer loyalty.</p>
                            

                                <div class="text-center"><a class="btn btn-primary" routerLink="/solutions/smallbusiness" role="button">Small Business</a></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card"><img class="card-img-top" src="assets/img/marketing/marketing02.png" alt="" />
                        <div class="card-body ps-0">
                            <h4 class="fw-bold"><a class="fw-bold text-decoration-none me-1"
                                    routerLink="/solutions/mediumenterprise">Need a platform to grow your business?</a></h4>
                            <p class="text-secondary">Stand out, be unique and exceed your customer expectations. Build
                                a brand your customers will love.</p>

                                <div class="text-center"><a class="btn btn-primary" routerLink="/solutions/mediumenterprise" role="button">Medium Enterprise</a></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card"><img class="card-img-top" src="assets/img/marketing/marketing03.png" alt="" />
                        <div class="card-body ps-0">
                            <h4>
                                <a class="fw-bold text-decoration-none me-1"
                                    routerLink="/solutions/largeorganisation">Looking for a secure, scalable solution?</a></h4>
                            <p class="text-secondary">Create AI powered experiences across all your customer
                                touchpoints and be available to your customers 24/7.</p>

                                <div class="text-center"><a class="btn btn-primary" routerLink="/solutions/largeorganisation" role="button">Large Organisation</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->


    <section class="py-8" id="team">
        <div class="bg-holder"
            style="background-image:url(assets/img/gallery/testimonial-bg.png);background-position:center;background-size:cover;">
        </div>
        <!--/.bg-holder-->

        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-xxl-5 text-center mx-auto mb-5">
                    <h1>Inspire Loyalty, Drive Growth</h1>
                    <p class="mb-5">We understand that exceptional customer experiences are the cornerstone of business
                        success.</p>
                </div>
            </div>
            <div class="carousel slide" id="carouselExampleDark" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="10000">
                        <div class="row h-100">
                            <div class="col-lg-6 mb-3 mb-md-0">
                                <div class="card card-span h-100 bg-transparent">
                                    <div class="card-body my-2">
                                        <div class="d-sm-flex"><img class="img-fluid me-3 me-md-2 me-lg-3"
                                                src="assets/img/gallery/mark.png" height="100" alt="..." />
                                            <div class="flex-1 ps-2">
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <p class="my-3">&quot;Our AI Agent brings us closer to our clients,
                                                    reducing operational burden and increasing our automated resolution
                                                    to help our team think about big picture levers we can pull to
                                                    create a better client experience.&quot;</p>
                                                <h6 class="mb-0 fs--1">-Erich Marks</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-3 mb-md-0">
                                <div class="card card-span h-100 bg-transparent">
                                    <div class="card-body my-2">
                                        <div class="d-sm-flex"><img class="img-fluid me-3 me-md-2 me-lg-3"
                                                src="assets/img/gallery/emily.png" height="100" alt="..." />
                                            <div class="flex-1 ps-2">
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <p class="my-3">&quot;We saw impressive gains when implementing the
                                                    scripted chatbot, but were blown away by the results of our
                                                    generative AI Agent. With the AI Agent, we no longer have to rely on
                                                    human intervention to address new customer inquiries that come
                                                    through when we've updated our product.&quot;</p>
                                                <h6 class="mb-0 fs--1">-Emily Hartman</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item" data-bs-interval="2000">
                        <div class="row h-100">
                            <div class="col-lg-6 mb-3 mb-md-0">
                                <div class="card card-span h-100 bg-transparent">
                                    <div class="card-body my-2">
                                        <div class="d-sm-flex"><img class="img-fluid me-3 me-md-2 me-lg-3"
                                                src="assets/img/gallery/mark.png" height="100" alt="..." />
                                            <div class="flex-1 ps-2">
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <p class="my-3">&quot;Our customer engagement has reached new heights
                                                    with Iconic.cx. The ability to maintain context and continuity
                                                    across different communication channels ensures a consistent and
                                                    smooth customer experience. Customers appreciate the personalized
                                                    attention and quick resolutions, and we’ve seen a noticeable
                                                    increase in customer satisfaction and loyalty.&quot;</p>
                                                <h6 class="mb-0 fs--1">-Erich Marks</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-3 mb-md-0">
                                <div class="card card-span h-100 bg-transparent">
                                    <div class="card-body my-2">
                                        <div class="d-sm-flex"><img class="img-fluid me-3 me-md-2 me-lg-3"
                                                src="assets/img/gallery/emily.png" height="100" alt="..." />
                                            <div class="flex-1 ps-2">
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <p class="my-3">&quot;I ve been using Servion for hosting many of my
                                                    wordpress websites and I truly appreciate how affordable it is&quot;
                                                </p>
                                                <h6 class="mb-0 fs--1">-Emily Hartman</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="row h-100">
                            <div class="col-lg-6 mb-3 mb-md-0">
                                <div class="card card-span h-100 bg-transparent">
                                    <div class="card-body my-2">
                                        <div class="d-sm-flex">
                                            <img class="img-fluid me-3 me-md-2 me-lg-3"
                                                src="assets/img/gallery/mark.png" height="100" alt="..." />
                                            <div class="flex-1 ps-2">
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <p class="my-3">&quot;What sets iconic.cx apart is its powerful AI
                                                    capabilities. The intelligent call routing feature ensures that
                                                    customers are always connected to the most suitable agent, enhancing
                                                    our first-call resolution rates. The AI-driven sentiment analysis
                                                    helps our agents gauge customer emotions and tailor their responses
                                                    accordingly, leading to more personalized and satisfying
                                                    interactions.&quot;</p>
                                                <h6 class="mb-0 fs--1">-Erich Marks</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-3 mb-md-0">
                                <div class="card card-span h-100 bg-transparent">
                                    <div class="card-body my-2">
                                        <div class="d-sm-flex"><img class="img-fluid me-3 me-md-2 me-lg-3"
                                                src="assets/img/gallery/emily.png" height="100" alt="..." />
                                            <div class="flex-1 ps-2">
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <svg class="bi bi-star-fill" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" fill="#FFCC00" viewBox="0 0 16 16">
                                                    <path
                                                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                                                    </path>
                                                </svg>
                                                <p class="my-3">&quot;The real-time analytics and reporting tools
                                                    provided by Iconic.cx are game-changers. We now have access to
                                                    detailed insights into our performance metrics, customer
                                                    satisfaction scores, and operational KPIs.&quot;</p>
                                                <h6 class="mb-0 fs--1">-Emily Hartman</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><a class="carousel-control-prev carousel-icon z-index-2" href="#carouselExampleDark" role="button"
                    data-bs-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span><span
                        class="visually-hidden">Previous</span></a><a
                    class="carousel-control-next carousel-icon z-index-2" href="#carouselExampleDark" role="button"
                    data-bs-slide="next"><span class="carousel-control-next-icon" aria-hidden="true"></span><span
                        class="visually-hidden">Next</span></a>
                <div class="row px-3 px-md-0 mt-4">
                    <div class="col-12 position-relative">
                        <ol class="carousel-indicators">
                            <li class="active" data-bs-target="#carouselExampleDark" data-bs-slide-to="0"></li>
                            <li data-bs-target="#carouselExampleDark" data-bs-slide-to="1"></li>
                            <li data-bs-target="#carouselExampleDark" data-bs-slide-to="2"></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="pb-lg-0 pt-md-8 py-0">

        <div class="container">
            <div class="row align-items-center justify-content-xl-between py-5 border-klean">
                <div class="col-auto col-md-4 col-lg-auto my-3 text-xl-start"><img
                        src="assets/img/gallery/robinhood.png" height="40" alt="..." /></div>
                <div class="col-auto col-md-4 col-lg-auto my-3 text-xl-start"><img src="assets/img/gallery/reddit.png"
                        height="40" alt="..." /></div>
                <div class="col-auto col-md-4 col-lg-auto my-3 text-xl-start"><img src="assets/img/gallery/upcase.png"
                        height="40" alt="..." /></div>
                <div class="col-auto col-md-4 col-lg-auto my-3 text-xl-start"><img src="assets/img/gallery/hubspot.png"
                        height="40" alt="..." /></div>
                <div class="col-auto col-md-4 col-lg-auto my-3 text-xl-start"><img src="assets/img/gallery/tunein.png"
                        height="40" alt="..." /></div>
            </div>
        </div>
        <!-- end of .container-->

    </section>

    <div class="divider"></div>


      <!-- ============================================-->
      <!-- <section> begin ============================-->
        <section class="pt-8 bg-soft-primary">

            <div class="container">
              <div class="row">
                <div class="col-lg-6 col-xxl-5 text-center mx-auto mb-5">
                  <h2>Experience the iconic.cx Difference</h2>
                  <p class="mb-5">Unify your communication channels and exceed expectations each time</p>
                </div>
              </div>
              <div class="row h-100">
                <div class="col-md-6 mb-5 mb-md-0 h-100">
                  <div class="card card-span bg-primary h-100">
                    <div class="card-body p-4">
                      <div class="d-flex justify-content-between"><img class="mb-3" src="assets/img/icons/rocket.png" height="80" alt="..." /><img src="assets/img/icons/clouds-1.png" height="90" alt="..." /></div>
                      <h5 class="text-light">Secure Cloud Platform</h5>
                      <p class="text-light lh-lg">Effortlessly connect iconic.cx with your existing systems. Our platform integrates with all major CRM and support software, ensuring a smooth transition and minimal disruption.</p>
                      <div class="text-end"><a class="btn btn-light" href="#" role="button">LEARN MORE
                          <svg class="bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                          </svg></a></div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 h-100">
                  <div class="card bg-secondary card-span h-100">
                    <div class="card-body p-4">
                      <div class="d-flex justify-content-between"><img class="mb-3" src="assets/img/icons/servion.png" height="80" alt="..." /><img src="assets/img/icons/logos.png" height="90" alt="..." /></div>
                      <h5 class="text-light">Personalised interactions</h5>
                      <p class="text-light lh-lg">Deliver personalized experiences at scale. iconic.cx leverages customer data to tailor interactions, ensuring every touchpoint feels unique and relevant.</p>
                      <div class="text-end"><a class="btn btn-light" href="#" role="button">LEARN MORE
                          <svg class="bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                          </svg></a></div>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
            <!-- end of .container-->
    
          </section>
          <!-- <section> close ============================-->
          <!-- ============================================-->
    

                <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section class="pt-8">

        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-xxl-5 text-center mx-auto">
              <h2>Transform Your Customer Experience</h2>
              <p class="mb-4">Join the ranks of leading companies who trust iconic.cx to deliver exceptional customer experiences</p>
            </div>
          </div>
          <div class="row align-items-center mt-5">
            <div class="col-md-5 col-lg-6 order-md-1 order-0"><img class="w-100" src="assets/img/gallery/join-us.png" alt="" /></div>
            <div class="col-md-7 col-lg-6 pe-lg-4 pe-xl-7">
              <div class="d-flex align-items-start"><img class="me-4" src="assets/img/icons/give-a-care.png" alt="" width="30" />
                <div class="flex-1">
                  <h5>Seamless Integrations </h5>
                  <p class="text-muted mb-4">iconic.cx integrates effortlessly with all major CRM and support software</p>
                </div>
              </div>
              <div class="d-flex align-items-start"><img class="me-4" src="assets/img/icons/tweak-as-you.png" alt="" width="30" />
                <div class="flex-1">
                  <h5>AI Driven Insights</h5>
                  <p class="text-muted mb-4">Gain deep insights into customer and user behavior.= with our advanced AI analytics tools</p>
                </div>
              </div>
              <div class="d-flex align-items-start"><img class="me-4" src="assets/img/icons/security.png" width="30" alt="..." />
                <div class="flex-1">
                  <h5>Secure & Robust</h5>
                  <p>Work securetly from anywhere on a platform the can automatically scale to meet your needs</p>
                </div>
              </div>

              <br/>
              <div class="text-center"><a class="btn btn-primary" routerLink="/register/service/fustionstarter" role="button">Register Free</a></div>

            </div>
          </div>
        </div>
        <!-- end of .container-->

      </section>
      <!-- <section> close ============================-->
      <!-- ============================================-->

</main>

<app-corporate-footer></app-corporate-footer>