import { Component, OnInit } from '@angular/core';
import { Tab } from 'src/app/_models/UI';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit{
  tab:Tab | undefined;
  isActive:boolean = false;

  ngOnInit(): void {
    if(!this.tab)
      {console.error('No Tab provided');
      return
  }
    

    this.isActive = this.tab.active
    
    this.tab.$active.subscribe(state => this.isActive = state);
  }
}
